body[class*="elementor-kit"]:not(.elementor-editor-active) {
	 background: #294558;  
	& > .elementor {
		opacity:0;
		transition:all .65s ease-out;
	}
	/*.preloader {
	  position: fixed;
	  top: 50%;
	  left:50%;
	  width: 10px;
	  height: 10px;
	  border-radius: 5px;
	  background-color: #34576f;
	  color: #34576f;
	  transform-origin: 5px 15px;
	  transform:translate(-50%,-50%);
	  animation: preloader 4s infinite linear;
	  &::before, 
	  &::after {
		  content: '';
		  display: inline-block;
		  position: absolute;
		}
		&::before {
		  left: -8.66px;
		  top: 15px;
		  width: 10px;
		  height: 10px;
		  border-radius: 5px;
		  background-color: #5e7b9a;
		  color: #5e7b9a;
		}
		&::after {
		  left: 8.66px;
		  top: 15px;
		  width: 10px;
		  height: 10px;
		  border-radius: 5px;
		  background-color: #294558;
		  color: #294558;
		}
	}

	@keyframes preloader {
	  0% {
	    transform: rotateZ(0deg) translate3d(0, 0, 0);
	  }
	  100% {
	    transform: rotateZ(720deg) translate3d(0, 0, 0);
	  }
	}*/
	#preloader-container {
	  position: absolute;
	  width: 100px;
	  height: 100px;
	  margin: auto;
	  transform: rotate(45deg);
	  top: 0; bottom: 0; left: 0; right: 0;
	}
	&.loaded {
		background: #fff;
		& > .elementor {
			opacity:1;
		}
		#preloader-container {
			opacity:0;
		}
	}
}
