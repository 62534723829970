body[class*="elementor-kit"] {
	form {
		.gform_wrapper.gravity-theme .gfield_label,
		label,
		legend {
			@include h4();
			@include letter-spacing(5);
			text-transform: none;
			color: $darkblue !important;
			padding-bottom: 15px !important;
		}
		.pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
		.pp-gravity-form .gfield textarea,
		.pp-gravity-form .gfield select,
		input,
		textarea,
		select,
		.nice-select {
			@include body-copy();
			padding: $container-spacing-mobile !important;
			background-color: $cream !important;
			border: none !important;
			border-radius: 0;
			color:$darkblue !important;
			font-family: $semplicitapro !important;
			&::placeholder {
				@include body-copy();
				font-family: $semplicitapro !important;
				color: $gray !important;
				opacity: 1 !important;
			}
			@media (min-width: $laptop) {
				padding: $container-spacing-mobile $container-spacing-desktop !important;
			}
			&[type="checkbox"],
			&[type="radio"] {
			    accent-color: $red;
			    padding: 0;
				& + label {
					@include body-copy();
				}
				&:checked {
					& + label {
						font-weight: 600;
					}
				}
			}
			&[type="submit"] {
				@include button();
				color: $white !important;
				padding: 10px 20px !important;
				border: 1px solid $red !important;
				background-color: $red !important;
				@media (min-width:$laptop) {
					&:hover {
						color: $red !important;
						border: 1px solid $red !important;
						background-color: $white !important;
					}
				}
			}
		}
		button {
			@include button();
			padding: 10px 20px !important;
			color: $white !important;
			border: 1px solid $red !important;
			&[type="submit"] {
				@media (min-width:$laptop) {
					&:hover {
						color: $red !important;
						border: 1px solid $red !important;
					}
				}
			}
		}
		.elementor-field-group {
			margin-bottom: $container-spacing-desktop !important;
		}
		.elementor-select-wrapper .select-caret-down-wrapper {
			inset-inline-end:  30px;
		}
		.nice-select {
			height: auto;
			width: 100%;
			&:after {
				border-bottom: none;
				border-top: 11px solid $red;
				border-left: 11px solid transparent;
				border-right: 11px solid transparent;
				width: 11px;
				transform: none;
				right: 30px;
			}
			.list {
				width: 100%;
			    border: none;
			    margin: 0;
			    max-height: 300px;
			    overflow: hidden;
			    z-index: 99999999999;
			    position: absolute;
			    overflow-y: scroll;
			    &::-webkit-scrollbar,
				&::-webkit-scrollbar-track,
				&::-webkit-scrollbar-thumb {
					border-radius: 12px;
				}
				&::-webkit-scrollbar {
					width: 4px;
				}
				&::-webkit-scrollbar-track {
					background-color: $cream;
				}
				&::-webkit-scrollbar-thumb {
					background-color: $brown;
				}
			    li {
			    	&:empty {
			    		display:none;
			    	}
			    	background: $cream;
			    	border: none;
			    	border-bottom: 1px solid $tangray;
			    	padding: $container-spacing-mobile;
			    	@media (min-width: $laptop) {
			    		padding: $container-spacing-mobile $container-spacing-desktop;
			    	}
			    	&:hover {
			    		color: $red;
			    	}
			    	&.selected {
			    		background-color: $cream;
			    	}
			    }
			}
		}
	}
	.background-cream {
		form {
			input,
			textarea,
			select,
			.nice-select {
				background-color: $white !important;
			}
			.nice-select {
				.list {
				    li {
				    	background: $white;
				    }
				}
			}
		}
	}
	/* gravity forms overrides */
	.pp-gravity-form .gform_wrapper .pp-gf-select-custom:after {
		display: none;
	}
	.gform_wrapper.gravity-theme .gfield-choice-input+label {
		padding-bottom: 0 !important;
	}
	.pp-gravity-form .gform_footer input[type="submit"], 
	.pp-gravity-form .gform_page_footer input[type="submit"] {
		width: 100% !important;
	}
}