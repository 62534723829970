body[class*="elementor-kit"] {
	.cvb-stats {
		position:relative;
		overflow:hidden;
		width:100%;
		height:100%;
		min-height: 650px;
		@media (min-width: $tablet) {
			min-height: 500px;			
		}
		@media (min-width: $laptop) {
			min-height: 750px;
		}
		.stat_card {
			width:100%;
			height:100%;
			position:absolute;
			.half {
				width: 100%;
				transform:translate(0,0);
				transition: all 350ms ease-in-out;
				background:$white;
				padding: $container-spacing-mobile;
				padding-bottom: $container-spacing-desktop;
				box-sizing: border-box;
				@media (min-width: $tablet) {
					width:50%;
					position:absolute;
					top:0;
					left:0;
					height:100%;
					display: flex;
					align-items: center;
				}
				@media (min-width: $laptop) {
					padding: $container-spacing-desktop;
				}
				&.right {
					height: 400px;
					@media(min-width: $tablet) {
						height: 100%;
						left: 50%;
					}
					.pagination {
						position: absolute;
						top: 0;
						right: 0;
						padding: $container-spacing-mobile/2 $container-spacing-desktop 0 0;
						border-top: 1px solid $red;
					}
					img,
					.card-content {
						margin-bottom: $container-spacing-desktop;
					}
					img {
						max-height: 54px;
						@media (min-width: $laptop) {
							max-height: 145px;
						}
					}
					.h1 {
						color: $darkblue;
						margin-bottom: $container-spacing-mobile/2;
						@media (min-width: $laptop) {
							margin-bottom: $container-spacing-desktop/2;
						}
					}
					.footnote {
						color: $darkblue;
						@include small-text();
						position: relative;
						padding: $container-spacing-mobile/2 0;
						background: url(../img/footnote-accent.png) no-repeat top left;
						a {
							color: $darkblue;
							@include small-text();
						}
					}
					.navigation {
						position: absolute;
						bottom: 0;
						right: 0;
						button {
							&.prev,
							&.next {
								border: none;
								color: $white;
							    display: inline-block;
			    				padding: 0;
			    				width: 35px;
			    				height: 35px;
								font-size: 0;
								background: $red url(../img/arrow-right.svg) no-repeat center center;
								background-size: 20px auto;
								margin-bottom: 0;
								@media (min-width: $laptop) {
									width: 60px;
									height: 60px;
								}
							}
							&.prev {
								background: $cream url(../img/arrow-left-red.svg) no-repeat center center;
								background-size: 20px auto;
							}
						}
					}
				}
				&.left {
					padding: 0;
					height: 250px;
					@media (min-width: $tablet) {
						height: 100%;
					}
				}
			}
			&.hidden {
				display: none;
			}
			&.open {
				.right {
					transform:translate(100%,0);
				}
				.left {
					transform:translate(-100%,0);
				}
			}
		}
	}
}