body[class*="elementor-kit"] {
	#breadcrumbs {
		padding: 0;
	    /*padding: 0 ($gutter * 0.5);
	    @media (min-width: $tablet) {
		    padding: 0 ($gutter-tablet * 0.5);
	    }
	    @media (min-width: $laptop) {
		    padding: 0 ($gutter-laptop * 0.5);
	    }
	    @media (min-width: $desktop) {
		    padding: 0 ($gutter-desktop * 0.5);
	    }*/
		*,
		a {
			font-family: $semplicitapro;
			@include line-height(24,12);
			font-size: 13px;
			@media (min-width: $tablet) {
				font-size: 16px;
				@include line-height(24,18);
			}
		}
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}