body[class*="elementor-kit"]:not(.elementor-editor-active) {
	h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5, p, ul li, button, .btn,
	.elementor-field-group, .gfield,
	.pp-flipbox-front .pp-flipbox-icon-image, .pp-flipbox-front .pp-flipbox-content,
	.cvb-related-content-card .cvb-related-content-card-img,
	.cvb-related-content-card-content .elementor-widget-theme-post-excerpt,
	.cvb-related-content-card-content .elementor-widget-button,
	.elementor-tab-title,
	.elementor-tab-content,
	.pp-flipbox-container {
		&.revealed {
		}
	}




	.cvb-fullwidth-callout .copy {
		transform: translate(0,-50px);
	}

	.cvb-fullwidth-callout .bg-img {
		transform: translate(0,50px);
	}

	& > div:not(.elementor-location-header):not(.elementor-popup-modal) {
		.elementor-accordion-item,
		h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5, 
		p, ul li, button, .btn {
			opacity:0;
			transform:translate(0,10px);
			transition:all .65s ease;
			&.revealed {
				opacity:1;
				transform:translate(0);
			}
		}		
	}
}