/** fonts **/

$semplicitapro: semplicitapro, sans-serif;

/** breakpoints **/

$tablet: 768px;
$lg-breakpoint: 992px;
$laptop: 1024px;
$desktop: 1500px;


/** colors **/

$lightblue: #AFCAD3;
$brown: #9D9D9A;
$tangray: #CFCEC8;
$lighttan: #D5D4CF;
$darkbeige: #DCDBD0;
$beige: #E1E0D8;
$offwhite: #F0F0EA;
$cream: #EAE9E1;
$white: #FFFFFF;
$red: #B01F24;
$gray: #9F9F9F;
$darkgray: #66717D;
$darkblue: #34576F;

/** styles **/

$box-shadow: 6px 6px 6px rgba(0,0,0,0.05);


/** spacing **/
 
$gutter: 15px;
$gutter-tablet: 20px;
$gutter-laptop: 30px;
$gutter-desktop: 40px;

$scale: 10; //used for calculating font sizes and max-width of containers

$container-spacing-mobile: 15px; //used for margin and padding on containers
$container-spacing-tablet: 20px;
$container-spacing-laptop: 30px;
$container-spacing-desktop: 40px;
