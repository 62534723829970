body[class*="elementor-kit"] {
	.cmplz-cookiebanner {
		grid-gap: 0px;
	    width: calc(100% - 20px);
	    .cmplz-message {
	    	margin-bottom: 20px;
	    	@media (min-width: $laptop) {
	    		margin-bottom: 0;
	    		max-width: 400px;
	    	}
	    	a {
	    		color: $white;
	    		font-size: 14px;
	    		transition: all .35s ease;
	    		display: inline-block;
	    		&:hover {
	    			color: $offwhite;
	    			transform: translate(10px,0);
	    		}
	    	}
	    }
		.cmplz-buttons {
			@media (max-width: $laptop) {
				gap: 0;
			}
			.cmplz-btn {
				background: $red !important;
				border-color: $red !important;
				color: $white !important;
				border-radius: 0 !important;
				font-size: 13px;
				&:hover {
					color: $red !important;
					border-color: $red !important;
					background: $white !important;
				}
				@media (max-width: $tablet) {
					margin-bottom: 10px;
				}
				@media (min-width: $tablet) and (max-width: $laptop) {
					margin-left: 10px;
					&:nth-child(1) {
						margin-left: 0;
					}
				}
				@media (min-width: $laptop) {
					min-width: 50px;
					width: auto;
				}
			}
		}
		.cmplz-close {
			font-size: 15px;
			line-height: 20px;
			width: 15px;
			height: 15px;
		    text-align: right;
			svg {
				width: 15px;
				height: 15px;
				transform: translate(13px,-8px);
			}
			&:hover {
				font-size: 15px;
				line-height: 20px;
			}
		}
		.cmplz-categories .cmplz-category .cmplz-banner-checkbox .cmplz-label:before,
		.cmplz-categories .cmplz-category .cmplz-banner-checkbox .cmplz-label:after {
			top: 0;
		}
		.cmplz-categories .cmplz-category .cmplz-banner-checkbox input.cmplz-consent-checkbox:checked + .cmplz-label::before {
    		background-color: rgba(255,255,255,1);
    	}
    	.cmplz-categories .cmplz-category .cmplz-banner-checkbox .cmplz-label:after {
    		background: $red !important;
		    height: 15px;
    		width: 15px;
    	}
	}
	#cmplz-manage-consent .cmplz-manage-consent {
		background-color: $darkblue !important;
		border-color: $darkblue !important;
		&:hover {
			@media (min-width: $laptop) {
				background-color: $white !important;
				color: $darkblue !important;
			}
		}	
	}	
}