body[class*="elementor-kit"] {
	&.home {
		& > .elementor {
		}
	}
	.cvb-home-page-hero {
		margin-bottom: 0 !important;
		h1 {
			z-index: 2;
			font-weight: 200;
			@media(min-width: $laptop) {
				font-size: 75px;
			}
			span {
				&.h2 {
				    color: #fff !important;
				    display: block;
				    font-weight: 400;
				    text-transform: none;
					@media(min-width: $laptop) {
					    margin-left: 110px;
					    max-width: 600px;
					}
				}
			}
		}
	}
	.cvb-home-page-tabs {
		position: relative;
		z-index: 2;
		@media (min-width: $tablet) {
			margin-top: -200px;
			.elementor-tabs-wrapper {
				height: 200px;
				.elementor-tab-title {
					height: 200px;
				}
			}
			&.background-cream {
				background: transparent;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
		@media (min-width: $laptop) {
			margin-top: -152px;
			@media(min-width: $laptop) {
				.elementor-tabs-wrapper {
					height: 152px;
					.elementor-tab-title {
						height: 152px;
					}
				}
			}
		}
		.elementor-tabs-wrapper,
		.elementor-tabs-content-wrapper {
			.elementor-tab-title,
			.elementor-tab-mobile-title {
				font-size: 16px;
				text-transform: uppercase;
				color: $white;
				font-weight: 500;
				line-height: 25px;
				position: relative;
	    		padding: $container-spacing-desktop $container-spacing-mobile;
				border-width:2px;
	    		background: $darkblue;
				margin-bottom: 0;
				&:before {
	    			background: $darkblue;					
				}
				@media (min-width: $laptop) {
	    			padding: $container-spacing-desktop;
	    		}
				&:nth-child(4n+2) { //every fourth child starting at the second
					background: #5E7B9A;
					&:before {
						background: #5E7B9A;						
					}
				}
				&:nth-child(4n+3) {
					background: #707070;
					&:before {
						background: #707070;						
					}
				}
				&:nth-child(4n+4) {
					background: $brown;
					&:before {
						background: $brown;						
					}
				}
				&.elementor-active {
					background: $white;
					color: $red;
					&:before {
						background: $white;						
					}
				}
			}			
			.elementor-tab-mobile-title {
				&:before {
					color: $white;
				}
				&.elementor-active {
					&:before {
						color: $red;
					}
				}
			}
			.elementor-tab-content {
				@media (max-width: $tablet) {
					margin-bottom: 0;					
				}
				@media (min-width: $laptop) {
	    			padding: $container-spacing-desktop 0;
	    		}
				&.elementor-active {
					border-top: 1px solid $red;
					@media (max-width: $tablet) {
						margin-bottom: 0;
					}
					@media (min-width: $tablet) {
						border-top: none;
					}
				}
			}
		}
	}
	/* home page particles */
	#particle-container { 
	  position:relative;
	    		}
	#particle-container:before {
	  content:"";
	  position:absolute;
	  background:url(../img/overlay.webp) no-repeat center center;
	  background-size:cover;
	  top:0;
	  left:0;
	  width:100%;
	  height:100%;
	  opacity:0.64;
	  z-index:1;
	}
	#particle-container canvas {
	    z-index:1;
	    position:relative;
	    max-height:90vh !important;
	}
	@media (max-width:767px) {
	    #particle-container canvas {
	        height: 300px !important;
	        opacity: 0 !important;
	    }
	}
}