body[class*="elementor-kit"] {
	.cvb-video {
		@media (min-width: $tablet) {
			margin-top: 120px;
		}
		@media (min-width: $desktop) {
			margin-top: 150px;
		}
		& > .elementor-container {
			&  > .elementor-column {
				&:first-child {
					@media (min-width: $tablet) and (max-width: $laptop) {
						padding:  0 10px;
					}
				}
				& > .elementor-widget-wrap {
					padding: 0;
				}
			}
		}
		.elementor-widget-video {
			& > .elementor-widget-container {
				overflow: visible;	
				@media (min-width: $tablet) and (max-width: $laptop) {
					margin-left: -10px;
				}			
				.elementor-custom-embed-image-overlay {
					position: relative;
					padding-bottom: 63%;
					@media (min-width: $tablet) {
						padding-bottom: 318px;
						padding-bottom: 0;
					}
					& > img {
						position: absolute;
					    top: 0;
					    left: 50%;
					    transform: translate(-50%,0);
					    width: 100vw;
					    min-width: 100vw;
					    @media(min-width: $tablet) {
					    	width: 57.75vw;
					    	min-width: 500px;
					    	max-width: 550px;
					    	left: 0;
					    	transform: translate(0);
					    	position: relative;
					    }
					    @media(min-width: $laptop) {
					    	min-width: 700px;
					    	max-width: 800px;
					    }
					    @media(min-width: $desktop) {
					    	min-width: 800px;
					    	max-width: 900px;
					    }
					}
				}
			}
		}
		.cvb-card {
			margin-top: -30px;
			padding: 30px 25px;
			a,
			.btn,
			.btn-secondary {
				margin-bottom: 0;
			}
			@media (min-width: $tablet) {
				margin-top: -90px;
			}
			@media (min-width: $laptop) {
				padding: 50px;
			}
			@media (min-width: $desktop) {
				padding: 90px;
				margin-top: -120px;
			}
		}
	}
}