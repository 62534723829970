body[class*="elementor-kit"] {
	#secondary-page-header {
		@media (min-width:$laptop) {
			min-height: 500px;
		}
		& > .elementor-container {
			@media (min-width:$laptop) {
				position: absolute;
			    bottom: 0;
			    left: 50%;
			    transform: translate(-50%,0);
			}
		}
		h1 {
			margin-bottom: -8px;
			@media (min-width: $tablet) {
				margin-bottom: -10px;				
			}
		}
	}
	.post-excerpt {
		font-size: 16px;
		@media (min-width: $laptop) {
			font-size: 18px;
		}
	}
}

/* override for 22 Jumps page */

body[class*=elementor-kit].page-id-10590 #secondary-page-header h1,
body[class*=elementor-kit].page-id-243 #secondary-page-header h1 {
    margin-bottom: 13px;
}