body[class*="elementor-kit"] {
	&.page-template-template-search {
		background: $cream;
		.search-container {
			@include content-well();
			@include container-margin();
			.search-row {
				width: 100%;
				display: flex;
				flex-direction: column;
				@media (min-width: $laptop) {
					flex-direction: row;
				}
				.search-column {
					width: 100%;
					&.filters-container {
						background: $white;
						box-sizing: border-box;
						padding: $container-spacing-mobile;
					    position: absolute;
					    z-index: 100;
					    top: 300px;
					    width: calc(100% - 60px);
					    left: $gutter;
    			    	display: none;
				    	&.active {
				    		display: block;
				    	}
				    	* {
				    		opacity: 1;
				    		transform: translate(0);
				    	}
				    	@media (min-width: $tablet) {
				    		width: 570px;
				    		left: 50%;
				    		transform: translate(calc(-50% - 15px),0);
				    	}
						@media (min-width: $laptop) {
							padding: 0 $container-spacing-desktop;
							display: block;
							margin-top: 72px;
							margin-bottom: 80px;
							position: relative;
							z-index: initial;
							width: 25%;
							margin-right: $gutter-laptop;
							transform: none;
							left: auto;
							top: auto;
						}
						@media (min-width: $desktop) {
							margin-right: $gutter-desktop;
						}
						h2 {
							margin-top: $container-spacing-desktop;
						}
						.close-filters {
							background: $red url(../img/icon-close-white.png) no-repeat center center;
							background-size: 12px auto;
							color: transparent;
							font-size: 0;
							height: 30px;
							width: 30px;
							padding: 0;
							position: absolute;
							top: 0;
							right: 0;
							transform: translate(100%,0);
							@media (min-width: $laptop) {
								display: none;
							}
						}
						.filter-group {
							margin-bottom: $container-spacing-desktop;
							p {
							    border-bottom: 1px solid $darkblue;
							    padding-bottom: 10px;
							    margin-bottom: 10px;
							}
							input[type="radio"],
							input[type="checkbox"] {
							    accent-color: $red;
							    padding: 0;
							    margin-right: 10px;
								& + span {
									@include body-copy();
								}
								&:checked {
									& + span {
										font-weight: 600;
									}
								}
							}
							.filter-item-page {
								display:none;
							}
						}
					}
					&.results-container {
						@media (min-width: $laptop) {
							width: calc(75% - $gutter-laptop);
						}
						@media (min-width: $desktop) {
							width: calc(75% - $gutter-desktop);
						}
						.search-header {
							font-size: 14px;
							display: flex;
							flex-direction: column-reverse;
							position: relative;
							padding: $container-spacing-mobile 0;
							@media (min-width: $tablet) {
								padding: 0;
								padding-left: calc(42px + $container-spacing-mobile);
								flex-direction: row;
								align-items: center;
								margin-bottom: $container-spacing-laptop;
							}
							@media (min-width: $laptop) {
								padding-left: 0;
							}
							.open-filters {
								background: $red url(../img/icon-filters.png) no-repeat center center;
								background-size: 19px auto;
								color: transparent;
								font-size: 0;
								height: 42px;
								width: 42px;
								position: absolute;
								top: $container-spacing-mobile;
								left: 0;
								@media (min-width: $tablet) {
									top: 0;
								}
								@media (min-width: $laptop) {
									display: none;
								}
							}
							.search-summary {
								@media (min-width: $tablet) {
									width: 50%;
								}
							}
							.search-sort {
							    display: flex;
								justify-content: flex-end;
								margin-bottom: $container-spacing-mobile;
								@media (min-width: $tablet) {
									width: 50%;
									margin: 0;
								}
								label {
									height: 42px;
									line-height: 42px;
									margin-right: 10px;
								}
								.nice-select {
									border-radius: 0;
									font-size: 14px;
									padding-left: 10px;
									min-width: 105px;
									&.open {
										border-color: $tangray;
									}
									&:after {
										border-bottom: none;
										border-top: 6px solid $red;
										border-left: 6px solid transparent;
										border-right: 6px solid transparent;
										transform: none;
										right: 10px;
										width: 1px;
									    height: 6px;
									    margin-top: -3px;
									}
									.list {
										width: 100%;
								    	border: 1px solid $tangray;
									    border-radius: 0;
									    margin: 0;
									    li {
									    	background: $white;
									    	border: none;
									    	border-bottom: 1px solid $tangray;
									    	padding: $container-spacing-mobile * 0.5;
									    	min-height: 20px;
									    	line-height: 2;
									    	font-size: 13px;
										    opacity: 1;
										    transform: none;
									    	&:hover {
									    		color: $red;
									    	}
									    	&.selected {
									    		background-color: $cream;
									    	}
									    }
									}
								}
							}
						}
						.no-results-message {
							display: none;
							padding: $container-spacing-mobile 0;
							text-align: center;
							@media (min-width: $laptop) {
								text-align: left;
							}
							&.active {
								display: block;
							}
						}
						.search-result {
							background: $white;
							box-sizing: border-box;
							padding: $container-spacing-mobile;
							margin-bottom: $container-spacing-mobile;
							* {
								opacity: 1;
								transform: translate(0);
							}
							@media (min-width: $laptop) {
								padding: $container-spacing-desktop;
								margin-bottom: $container-spacing-desktop;
								display: flex;
								flex-direction: row;
							}
							.result-content {
								@media (min-width: $laptop) {
									width: 60%;
									min-width: 60%;
								}
								.eyebrow,
								h3 {
									margin-top: 0;
									margin-bottom: $container-spacing-mobile;
								}
								.search-result-excerpt {
									margin-top: 0;
									margin-bottom: $container-spacing-laptop;
								}
								.btn {
									margin-bottom: 0;
								}
							}
							.result-thumbnail {
								background-size: cover;
								background-repeat: no-repeat;
								background-position: center center;
								@media (min-width: $laptop) {
									width: 40%;
									min-width: 40%;
									min-height: 100%;
									margin-left: $container-spacing-mobile;
								}
							}
							&.no-thumbnail {
								.result-content {
									@media (min-width: $laptop) {
										width: 100%;
										min-width: 100%;
									}
								}
								.result-thumbnail {
									display: none;
								}
							}
						}
						.search-pagination {
							display: flex;
							justify-content: space-between;
							button {
								opacity: 1;
								transform: translate(0);
								background: transparent;
								padding: 0;
								color: $red;
								border: none;
								&.inactive {
									color: $gray;
									cursor: default;
								}
								&.previous-page,
								&.next-page {
									font-size: 0;
									margin-bottom: 0;
									svg {
										margin-top: 14px;
									}
									@media (min-width: $tablet) {
										font-size: inherit;
										height: 41px;
										line-height: 41px;
										svg {
											margin-top: 0;											
										}
									}
								}
								&.previous-page {
									svg {
										margin-right: 5px;
									}
								}
								&.next-page {
									svg {
										margin-left: 5px;
									}
								}
							}
							.page-numbers {
								display: flex;
								button {
									margin: 0 10px;
									position: relative;
									line-height: 41px;
									&:hover {
										text-decoration: underline;
									}
									&.active {
										color: $darkblue;
										&:after {
											content: "";
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translate(-50%,-50%);
											width: 25px;
											height: 25px;
											border: 2px solid $darkblue;
										}
									}
								}

								button {
									&:first-child,
									&.active-sibling,
									&.active,
									&.active + button,
									&:last-child {
									  display: inline-block !important;
									}
									&:first-child:nth-last-child(n+8) {
									  $how-many-on-ends: 5;
									  &~button {
									    display: none;
									    &.active-sibling:before {
								        @include ellipsis(true);
									    }
									    &.active + button:after {
								        @include ellipsis(false);
									    }
									    &:nth-last-child(-n+#{$how-many-on-ends}) {
									      display: inline-block;
									    }
									    &:nth-last-child(#{$how-many-on-ends}) {
									      &:before {
									        @include ellipsis(true);
									      }
									    }
									    &:nth-child(-n+#{$how-many-on-ends - 3}),
									    &:nth-last-child(-n+#{$how-many-on-ends - 3}),
									    &.active-sibling:nth-last-child(-n+#{$how-many-on-ends - 1}) {
									      &:before, &:after {
									        display: none !important;
									      }
									    }
									  }
									  &.active,
									  &~button.active {
									    &~button:nth-last-child(-n+#{$how-many-on-ends}) {
									      display: none;
									      &:before {
									       display: none;
									      }
									    }
									    &~button:nth-child(-n+#{$how-many-on-ends}) {
									      display: inline-block;
									    }
									    &~button:nth-child(-n+#{$how-many-on-ends - 1}) {
									      &:after {
									        display: none;
									      }
									    }
									    &~button:nth-child(#{$how-many-on-ends}) {
									      &:after {
									        @include ellipsis(false);
									      }
									    }
									    &:before, &:after {
									      display: none;
									    }
									  }
									}
									@media (min-width: $tablet) {
										&:nth-child(2),
										&:nth-last-child(2),
										&:nth-last-child(3) {
										  display: inline-block !important;
										}
									}
								}
							}
						}
					}
				}
				.filters-overlay {
    				background-color:$darkgray;
					opacity: 0.87;
			    	position: fixed;
			    	top: 0;
			    	left: 0;
			    	width: 100vw;
			    	height: 100vh;
			    	z-index: 99;
			    	display: none;
			    	&.active {
			    		display: block;
			    	}
			    	@media (min-width: $laptop) {
						display: none;
					}
			    }
			}
			&.search-form-wrapper {
				padding-top: 30px;
				margin-bottom: 30px;
				@media (min-width: $laptop) {
					padding-top: 100px;
					@include container-margin();
				}
				form {
				    width: 100%;
				    display: flex;
				    flex-direction: column;
				    position: relative;
					label {
						width: 100%;
						text-align: center;
						font-weight: bold;
					}
					input {
						&[type="search"] {
							background: $white !important;	
							padding-right: 98px !important;
							height:50px;
							border-radius:0;
							-webkit-appearance:none;
							@media (min-width: $laptop) {
								height: 58px;
							}
							&::-webkit-search-cancel-button {
							  -webkit-appearance: none;
							  appearance: none;
							  height: 18px;
							  width: 18px;
							  background-image: url(../img/icon-close.png);
							  background-repeat: no-repeat;
							  background-position: center center;
							  background-size: 18px 18px;
							}
							&:focus-visible {
								outline: 2px solid $darkblue;
							}
						}
						&[type="submit"] {
							opacity: 0;							
						}
					}
					.submit-wrapper {
						background: $red url(../img/icon-search.png) no-repeat center center;
						background-size: 20px auto;
						width: 50px;
						height: 50px;
						position: absolute;
						bottom: 0;
						right: 0;
						@media (min-width: $laptop) {
							background-size: 30px auto;
							width: 58px;
							height: 58px;
						}
					}		
				}
			}
		}
	}
}