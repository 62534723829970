body[class*="elementor-kit"] {
	div[data-elementor-type="footer"] {
		border-top: 1px solid $red;
		background: $white;
		font-size: 14px;
		line-height: 1.4;
		@media(min-width: $laptop) {
			font-size: 16px;		
		}
		section.elementor-top-section {
			margin-bottom: $container-spacing-mobile;
		}
		ul{
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				padding: 0;
				margin: 0;
				@media (min-width: $laptop) {
					margin: 0 0 14px 0;					
				}
			}
		}
		a {
			font-size: 14px;
			@media (min-width: $laptop) {
				font-size: 16px;		
			}
			text-decoration: none;
			&[href^="mailto"] {
				color: $red !important;
			}
			&:hover {
				@media (min-width: $laptop) {
					text-decoration: underline;
				}
			}	
		}
		.small-text {
			@media (min-width: $laptop) {
			    height: 100%;
			    display: flex;
			    align-items: flex-end;
			}
			a {
				font-size: 12px;
				color: $darkgray;
				text-decoration: underline;
				&:hover {
					@media(min-width: $laptop) {
						color: $red;
					}
				}	
			}
		}
		.elementor-button {
			font-size: 14px;
			@media(min-width: $laptop) {
				font-size: 16px;		
			}
		}
		.footer-social-column {
			.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
				padding: 0;
			}
			.elementor-icon-list-item,
			.elementor-widget.elementor-align-left .elementor-icon-list-item {
				span {
				    height: 100%;
				    width: 100%;
				    justify-content: center;
				    align-items: center;
				}
				a {
					background: $darkgray;
					border-radius: 50%;
					width: 30px;
					height: 30px;
					color: $white !important;
				    justify-content: center;
		    		text-align: center;
		    		display: inline-block;
		    		line-height: 30px;
		    		&:hover {
		    			background: $red;
		    			color: $white !important;
		    			text-decoration: none;
		    		}
				}
			}
			.elementor-widget .elementor-icon-list-icon+.elementor-icon-list-text {
				display: none;
			}
			.elementor-widget {
				display: flex;
			    height: 100%;
			    align-items: center;
	    	    justify-content: center;
	    	    padding-bottom: 20px;
			    @media (min-width: $desktop) {
			    	padding-bottom: 0;
			    	justify-content:flex-end;
			    }
			}
		}
		#footer-logo {
			@media (min-width: $tablet) {
				padding-right: 16px;
			}
			.elementor-widget-container > a {
				@media (min-width: $tablet) {
					position: relative;
				}
				&:after {
					@media (min-width: $tablet) {
						content: "";
						width: 1px;
						height: 40px;
						position: absolute;
						top: 50%;
						right:0;
						transform: translate(20px,-50%);
						border-right: 1px solid $darkgray;
					}
					@media (min-width: $laptop) {
						height: 80px;
						transform: translate(70px,-50%);						
					}
					@media (min-width: $desktop) {
						transform: translate(30px,-50%);						
					}
				}
			}
		}
	}
}