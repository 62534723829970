body[class*="elementor-kit"] {
	.elementor-widget-pp-flipbox {
		.pp-flipbox-container {
			height: 350px;
			@media (min-width: $tablet) {
				height: 400px;	
			}
			@media (min-width: $laptop) {
				height: 500px;	
			}
		}
		.pp-flipbox-icon-image,
		.pp-flipbox-icon-image-back {
			position: absolute !important;
			top: $container-spacing-tablet !important;
			left: $container-spacing-tablet !important;
			@include h5();
			color: $white !important;
			text-transform: uppercase;
			@media (min-width: $laptop) {
				top: $container-spacing-desktop !important;
				left:  $container-spacing-desktop !important;
			}
		}
		.pp-flipbox-overlay {
			padding: $container-spacing-tablet;
			text-align: left !important;
			@media (min-width: $laptop) {
				padding: $container-spacing-desktop;
			}
		}
		.pp-flipbox-front,
		.pp-flipbox-back {
			border-top: 6px solid $red;
		}
		.pp-flipbox-front {
			background-color: rgba(97, 136, 173,1);
			background-blend-mode: multiply;
			.pp-flipbox-overlay {
				background-color: rgba(97, 136, 173,0) !important;
			}
			.pp-flipbox-heading {
				margin: 0;
				@media (min-width: $laptop) {
					margin: 34px 0;
				}	
			}
		}
		.pp-flipbox-back {
			background:$cream;
			.pp-flipbox-icon-image-back {
				color: $darkblue !important;
			}
			.pp-flipbox-content {
				color: $darkgray;
				margin-bottom: $gutter-tablet;			
			}
			.pp-flipbox-button {
				@include button-style-secondary();
			}
		}
	}
	.elementor-col-50 {
		.elementor-widget-pp-flipbox {
			@media (min-width: $tablet) {
				width: 100%;
			}
		}
		& + .elementor-col-50 {
			.elementor-widget-pp-flipbox {
			}
		}
	}
}