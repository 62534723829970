body[class*="elementor-kit"] {
	.mobile-search-link {
		display: inline-block;
		float: right;
		margin: 15px 30px 0 0;
		width: 27px;
		height: 27px;	
		@media (min-width: $laptop) {
			display: none;
		}
	}
	.header-newsletter-link {
		display: inline-block;
		float: right;
		margin: 15px 30px 0 0;
		width: 27px;
		height: 27px;	
		@media (min-width: $laptop) {
			display: none;
		}	
	}
    button.open-menu {
	    background: $red url(../img/icon-close.png) no-repeat center center;
	    background-size: 13px auto;
    	width: 35px;
    	height: 35px;
    	padding: 7px;
    	box-sizing: border-box;
    	float: right;
    	margin: 10px 0;
    	transition: none;
    	span {
    		background: $white;
    		height: 2px;
    		width: 100%;
    		margin: 4px 0;
    		display: block;
    	}
    	&.active {
		    background: $red url(../img/icon-close-white.png) no-repeat center center;
		    background-size: 13px auto;
    		span {
	    		display: none;
    		}
    	}
    	@media (min-width: $laptop) {
    		display: none;
    	}
    }
	.nav-container {
	    position: fixed;
	    top:0;
	    right:0;
	    width: 100vw;
	    height: 100vh;
	    background: $white;
	    transform: translate(100%,0);
		transition: all 0.8s ease;
		overflow: auto;
	    &.opened {
	    	transform: translate(0);
	    }
	    @media (min-width:$laptop) {
	      position:relative;
	      width:100%;
	      height:auto;
	      top: auto !important;
  	      transform: translate(0);
  	      overflow: visible;
	    }
		.nav-title {
			width:100%;
			background:#fff;
			position:relative;
			z-index:100;
			ul {
				margin: 0;
				padding: 0;
				background-color: #fff;
				@media (min-width:$laptop) {
					display:flex;
					flex-direction:row;
					width:100%;
					max-width:768px;
					margin:0 auto;
					justify-content:space-between;
					position:relative;
					z-index:100;
				}
				li {
					color: $red;
					list-style: none;
					margin: 0;
					padding: 0;
					background: #fff;
					&.header-search {
						display: none;
						@media (min-width: $laptop) {
							padding-right: $container-spacing-mobile;
							margin-right: -$container-spacing-mobile;
							display: flex;
							align-items: center;
							input {
								position: absolute;
							    top: 50%;
							    right: 0;
							    transform: translate(0,-50%);
							    height: 100%;
							    width: 100vw;
							    background: $cream;
							    -webkit-appearance: none;
							    border: none;
							    outline: none;
							    @include body-copy();
							    transition: all .3s ease-out;
							    max-width: 0;
							    padding: 0;
							    &:focus {
							    	border: 1px solid $tangray;
							    }
							    &.active {
								    padding: 0 $container-spacing-desktop 0 $container-spacing-mobile;
								    border-bottom: 3px solid $red;
							    	max-width: 45vw;
								    transition: all .65s ease-out;
							    }
							}
							img {
								position: relative;
								z-index: 1;
								cursor: pointer;
								width: 27px;
								height: 27px;
							}
						}
					}
					a {
						text-decoration: none;
						display: block;
						padding: 1em 1em 1em 1.2em;
						outline: none;
						font-size:30px;
						color: $red;
						border-bottom: 3px solid rgba(112, 112, 112,.15);
						@media (min-width:$laptop) {
							border:none;
							font-size:14px;
							color:$darkblue;
							white-space: nowrap;
							&.active,
							&:hover {
								color:$red;
								background:#EFF1F2;
							}
						}
						span {
							font-size: 12px;
							float:right;
							@media (min-width:$laptop) {
								display:none;
							}
						}
					}
					&.donate-link-wrapper {
				    	a {
				    		background: $red;
				    		color: $white;
				    		text-transform: uppercase;
				    		text-align: center;
				    		font-size: 16px;
				    		@include letter-spacing(50);
				    		border: none;
				    	}
				    	@media (min-width: $laptop) {
				    		display: none;
				    	}
				    }
				}
			}
		}
		div[id*="layer"] {
			width:100vw;
			height:100%;
			@media (min-width:$laptop) {
				height:auto;
				width: 100%;
				@include remToPxMaxWidth(100);
				left: 50%;
				transform: translate(-50%,-100%);
			}
			&.sub-menu {
				position:absolute;
				top:0px;
				width:100vw;
				min-height:100vh;
				overflow:auto;
				overflow-x: hidden;
				box-sizing:border-box;
				background-color:  #fff;
				padding:$container-spacing-mobile;
				z-index: 200;
				@media (min-width:$laptop) {
					position: fixed;
					padding: $container-spacing-desktop;
					top:134px;
					height: auto;
					min-height: 75vh;
					width: 150vw;
					min-width: 150vw;
					background: $white;
				}
				.back-link {
					text-transform:uppercase;
					color:$red;
					text-decoration:none;
					font-size:14px;
					display: block;
					margin: $container-spacing-mobile 0;
					svg {
						margin-right: 5px;
					}
					@media(min-width:$laptop) {
						display:none;
					}
				}
				.columns {
					max-height: 100%;
					@media (min-width: $laptop) {
						@include remToPxMaxWidth(100);
						margin: 0 auto;
						overflow: auto;
						padding-right: $container-spacing-mobile;
						&::-webkit-scrollbar,
						&::-webkit-scrollbar-track,
						&::-webkit-scrollbar-thumb {
							border-radius: 12px;
						}
						&::-webkit-scrollbar {
							width: 4px;
						}
						&::-webkit-scrollbar-track {
							background-color: transparent;
						}
						&::-webkit-scrollbar-thumb {
							background-color: $tangray;
						}
					}
					@media (min-width: $desktop) {
						@include remToPxMaxWidth(120);
					}
					h2 {
						margin-top: 0;
						border-bottom: 1px solid $darkblue;
						padding-bottom: 15px;
						margin-bottom: 20px;
					}		
					div[class^="column"] {
						@media (min-width:$laptop) {
							&:not(.column-2) {
								display:inline-block;
								float:left;              
							}
						}
					}
					.column-1 {
						margin-bottom: 20px;
						@media (min-width: $tablet) {
							img {
								display: none;
							}
						}
						@media (min-width:$laptop) {
							margin-bottom: 0;
							width:33%;
							margin-right:$container-spacing-desktop;
							img {
								display: block;
							}
						}
					}
					.column-2 {
						.link-wrapper {
							background:#DBDBDB;
							padding: $container-spacing-mobile;
							text-decoration:none;
							border-left:2px solid $red;
							margin-bottom:5px;
							width:100%;
							display:inline-block;
							@media (min-width:$laptop) {
								background:transparent;
								color:$darkblue;
								border:none;
								padding:0;
								margin:0 0 30px 0;
							}
							a {
								font-size:15px;
								font-weight: bold;
								color:$red;
								text-decoration:none;
								@media (min-width: $laptop) {
									font-size: 18px;
									font-weight: 500;
									&:hover {
										text-decoration: underline;
									}
								}
							} 
							p {
								display:none;
								@media (min-width:$laptop) {
									display:block;
									font-size:14px;
								}
							}
						}
						@media (min-width:$laptop) {
							width:calc(67% - $container-spacing-desktop);
							display:flex;
							flex-direction:row;
							flex-wrap: wrap;
							.link-wrapper {
								width:calc(50% - ($container-spacing-desktop * 0.5));
								box-sizing:border-box;
								&:nth-child(odd) {
									margin-right: $container-spacing-desktop;
								}
							}
						}
					}
					.column-3 {
						width:100%;
						position: relative;
						button {
							&.previous-story,
							&.next-story {
								display: none;
								@media (min-width: $laptop) {
									display: inline-block;
									opacity: 1;
									transform: none;
								    position: absolute;
									background: $red url(../img/arrow-left.svg) no-repeat center center;
									background-size: 20px auto;
									top: 50%;
								    left: 0;
								    font-size: 0;
								    color: transparent;
								    width: 40px;	 
								    height: 40px;
							    	&[disabled] {
										background: $tangray url(../img/arrow-left.svg) no-repeat center center;
										background-size: 20px auto;
									}  			
								}
							}
							&.next-story {
								@media (min-width: $laptop) {
									background: $red url(../img/arrow-right.svg) no-repeat center center;
									background-size: 20px auto;
									left: auto;
									right: 0;
							    	&[disabled] {
										background: $tangray url(../img/arrow-right.svg) no-repeat center center;
										background-size: 20px auto;
									}  			
								}
							}
						}
						h5 {
							border-bottom: 1px solid $darkblue;
							padding-bottom: 10px;
							margin-bottom: 15px;
							@media (min-width: $laptop) {
								margin-top: 15px;
							}
						}
						.menu-featured-stories {
							@media (min-width: $laptop) {
								overflow: hidden;
								margin: 0 auto;
								width: 100%;
								max-width: 90%;
							}
							.card-row {
								@media (min-width: $laptop) {
									display: flex;
									width: 100%;
									flex-wrap: nowrap;
									justify-content: flex-start;
									transition: all 1s ease-in-out;
									padding: 10px;
									&.translate-left {
										transform: translate(-50%,0);					
									}
								}
								&.single-card {
									@media (min-width: $laptop) {
										justify-content: center;
									}
								}
								.card {
									display:flex;
									flex-direction:row;
									padding:15px;
									box-shadow:0 0 10px rgba(0,0,0,.2);
									border-top:6px solid $red;
									@media (min-width:$laptop) {
										min-width: calc(50% - ($container-spacing-desktop * 0.5));
										max-width: calc(50% - ($container-spacing-desktop * 0.5));
										width: calc(50% - ($container-spacing-desktop * 0.5));
										float:left;
										&:nth-child(2) {
											margin:0 $container-spacing-desktop;
										}
									}
									* {
										opacity: 1;
										transform: translate(0);
									}
									.img-wrapper {
										margin-right: 15px;
										max-width:100px;
										max-height: 100px;
										img {
											opacity: 0;
										}
										@media (min-width:$laptop) {
											max-width:120px;
											max-height: 120px;
										}
									}
									.card-content {
										.eyebrow {
											@include h5();
											margin: 0 0 $container-spacing-mobile;
											font-size: 12px;
										}
										p {
											margin: 0 0 $container-spacing-mobile;
											font-size: 14px;
										}
										a {
											font-size: 12px;
											margin-bottom: 0;
											padding: 5px 0;
										}
									}
								}
							}
							&.without-arrows {
								@media (min-width: $laptop) {
									max-width: 100%;
								}
								.card-row {
									.card {
										@media (min-width: $laptop) {
											&:nth-child(2) {
												margin:0 0 0 $container-spacing-desktop;
											}											
										}
									}
								}
							}
	      	
						}
					}
				}
				&.hide {
					transform:translate(100%,0);
					transition: all 0.65s ease-out;
					@media (min-width:$laptop) {
						transform:translate(-50%,-$container-spacing-desktop);
						z-index:-1;
						opacity:0;
					}
				}
				&.show-menu {
					transform:translate(0) !important;
					transition: all .8s ease;
					@media (min-width:$laptop) {
						opacity:1;
						z-index:99;
						transform:translate(-50%,0) !important;
					}
				}
			}
		}
	}
	section.mega_nav_container {
		overflow: hidden;
		button.close-menu {
			font-size: 0;
			color: transparent;
			background: $cream url(../img/icon-close.png) no-repeat center center;
			background-size: 13px auto;
			border: 1px solid $cream;
			display: none;
			opacity: 0;
			transition: all 1s ease;
		}
		&.menu_open {
			@media (min-width: $laptop) {
				overflow: visible;
				button.close-menu {
					display: block;
					opacity: 1;
					position: fixed;
					top: 160px;
					z-index: 101;
					right: 0;
					width: 40px;	 
					height: 40px;
					&:hover {
						background: $white url(../img/icon-close.png) no-repeat center center;
						background-size: 13px auto;
						border: 1px solid $cream;
					}
				}

			}
		}
	}
	&.logged-in {
		.nav-container {
			top: 46px;
		}
	}
}