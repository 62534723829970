body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	min-height: 100vh;
	@include body-copy();
	& > .elementor {
		overflow:auto;
		overflow-x:hidden;
	}
	&.menu-opened {
		@media (min-width: $laptop) {
			&:before {
				content: "";
				position: fixed;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100vh;
				background: $darkblue;
				opacity: 0.75;
				z-index: 1000;
			}
		}
	}
}


img {
	width:100%;
	max-width:100%;
	&.rounded {
		border-radius: 50%;
	}
}