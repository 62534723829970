body[class*="elementor-kit"] {
	.cvb-fullwidth-callout {
		position: relative;
		margin-top:  $container-spacing-mobile * 4;
		@media (min-width: $laptop) {
			margin-top:  $container-spacing-mobile * 6;			
		}
		img {
			opacity: 0;
			@media (min-width: $tablet) {
				margin-top:  $container-spacing-mobile * 6;			
			}
		}
		.bg-img {
			background: $darkblue;
			position: absolute;
			top: 0;
			right: $gutter;
			width: calc(100vw - $gutter);
			height: 400px;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			@media (min-width: $tablet) {
				top: $container-spacing-desktop;
				height: 450px;
			}
			@media (min-width: $laptop) {
				height: 100%;
			}
		}
		.copy {
			background: $white;
			width: 82%;
			margin-left: 18%;
			padding: $container-spacing-tablet;
			border-top: 6px solid $red;
			margin-top: -($container-spacing-mobile * 2);
			position: relative;
			@media (min-width: $tablet) {
				position: absolute;
				top: 0;
				right: 0;
				width: 50%;
				padding-right:0;
			}
			@media (min-width: $laptop) {
				margin-top: -($container-spacing-desktop * 2);
				padding: ($container-spacing-desktop * 2) 0 ($container-spacing-desktop * 2) $container-spacing-desktop;
			}
			a {
				&:last-child {
					margin-bottom: 0;
				}
			}
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: 1px;
				width: 100%;
				height: calc(100% + 6px);
				background: $white;
				transform: translate(100%,0);
				border-top: 6px solid $red;
			}
			.link-button {
				position: absolute;
				top: 0;
				left: 0;
				transform: translate(-100%,-6px);
				background-color: $red !important;
				color: $white;
				padding: 15px;
				&:hover {
					@media (min-width: $laptop) {
						background: $white !important;
						color: $red !important;
					}
				}
				@media (min-width: $tablet) {
					bottom:0;
					top:auto;
					transform: translate(-100%,0);
				}
			}
			.callout-content {
				@include text-spacing();
			}
		}
		&.has-cream-background {
			.copy {
				background: $cream;
				&:after {
					background: $cream;
				}
			}
		}
	}
}