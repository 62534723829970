body[class*="elementor-kit"] {
	.elementor-accordion-item {
		margin-bottom: $container-spacing-mobile;
		border: 1px solid $tangray;
		@media (min-width: $laptop) {
			margin-bottom: $container-spacing-desktop;
		}
		&:last-child {
			margin-bottom: 0;
		}
		& +.elementor-accordion-item {
			border-top: 1px solid $tangray;
		}
		.elementor-tab-title {
    		padding: $container-spacing-desktop $container-spacing-mobile;
			@media (min-width: $laptop) {
    			padding: $container-spacing-desktop;
    		}
    		a {
    			color: $darkblue;
    			&.elementor-accordion-title {
    				text-decoration: none;
    			}
    		}
    		.elementor-accordion-icon {
    			color: $darkblue;
    		}
    		&:hover {
    			@media (min-width: $laptop) {
    				.elementor-accordion-icon {
		    			color: $red;
		    		}	
    			}
    		}
    		&.elementor-active {
				a,
				.elementor-accordion-icon {
					color: $red;
				}
			}
    	}
    	.elementor-tab-content {
			border-top: 1px solid $tangray;
    		padding: $container-spacing-desktop $container-spacing-mobile;
			@media (min-width: $laptop) {
    			padding: $container-spacing-desktop;
    		}
    		&.elementor-active {
				border-top: 1px solid $red;
			}
		}
	}
	section {
		&.background-cream {
			.elementor-accordion-item {
				background: #fff;
				border: none !important;
			}
		}
	}
}