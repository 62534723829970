@charset "UTF-8";
@import url("https://use.typekit.net/wss5vva.css"); /** fonts **/
/** breakpoints **/
/** colors **/
/** styles **/
/** spacing **/
/* ================================
** Utility
================================ */
/* ================================
** Typography
================================ */
/* ================================
** Spacing
================================ */
/* ================================
** Layout / Structure
================================ */
/*
desktop: http://gridcalculator.dk/#/1300/12/50/0
laptop: http://gridcalculator.dk/#/1000/12/40/0
tablet: http://gridcalculator.dk/#/600/12/20/0
mobile: http://gridcalculator.dk/#/350/12/15/0
*/
body[class*=elementor-kit] {
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] {
    font-size: 17px;
    line-height: 1.6470588235;
  }
}
body[class*=elementor-kit] h1,
body[class*=elementor-kit] .h1 {
  font-family: semplicitapro, sans-serif;
  color: #FFFFFF;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 38px;
  line-height: 1.1842105263;
  margin-top: 0;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] h1,
body[class*=elementor-kit] .h1 {
    font-size: 60px;
    line-height: 1.0833333333;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h1,
body[class*=elementor-kit] .h1 {
    font-size: 90px;
    line-height: 1;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] h1,
body[class*=elementor-kit] .h1 {
    font-size: 100px;
    line-height: 1;
  }
}
body[class*=elementor-kit] h1.elementor-widget-heading, body[class*=elementor-kit] h1.elementor-widget-heading,
body[class*=elementor-kit] .h1.elementor-widget-heading,
body[class*=elementor-kit] .h1.elementor-widget-heading {
  font-family: semplicitapro, sans-serif;
  color: #FFFFFF;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 38px;
  line-height: 1.1842105263;
  margin-top: 0;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] h1.elementor-widget-heading, body[class*=elementor-kit] h1.elementor-widget-heading,
body[class*=elementor-kit] .h1.elementor-widget-heading,
body[class*=elementor-kit] .h1.elementor-widget-heading {
    font-size: 60px;
    line-height: 1.0833333333;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h1.elementor-widget-heading, body[class*=elementor-kit] h1.elementor-widget-heading,
body[class*=elementor-kit] .h1.elementor-widget-heading,
body[class*=elementor-kit] .h1.elementor-widget-heading {
    font-size: 90px;
    line-height: 1;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] h1.elementor-widget-heading, body[class*=elementor-kit] h1.elementor-widget-heading,
body[class*=elementor-kit] .h1.elementor-widget-heading,
body[class*=elementor-kit] .h1.elementor-widget-heading {
    font-size: 100px;
    line-height: 1;
  }
}
body[class*=elementor-kit] h2,
body[class*=elementor-kit] .h2 {
  color: #34576F !important;
  font-weight: 400;
  font-family: semplicitapro, sans-serif;
  font-size: 20px;
  line-height: 1.25;
  margin-top: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h2,
body[class*=elementor-kit] .h2 {
    font-size: 30px;
    line-height: 1.3333333333;
  }
}
body[class*=elementor-kit] h2.elementor-widget-heading, body[class*=elementor-kit] h2.elementor-widget-heading,
body[class*=elementor-kit] .h2.elementor-widget-heading,
body[class*=elementor-kit] .h2.elementor-widget-heading {
  color: #34576F !important;
  font-weight: 400;
  font-family: semplicitapro, sans-serif;
  font-size: 20px;
  line-height: 1.25;
  margin-top: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h2.elementor-widget-heading, body[class*=elementor-kit] h2.elementor-widget-heading,
body[class*=elementor-kit] .h2.elementor-widget-heading,
body[class*=elementor-kit] .h2.elementor-widget-heading {
    font-size: 30px;
    line-height: 1.3333333333;
  }
}
body[class*=elementor-kit] h3,
body[class*=elementor-kit] .h3 {
  color: #34576F !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.375;
  margin-top: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h3,
body[class*=elementor-kit] .h3 {
    font-size: 22px;
    line-height: 1.3076923077;
  }
}
body[class*=elementor-kit] h3.elementor-widget-heading, body[class*=elementor-kit] h3.elementor-widget-heading,
body[class*=elementor-kit] .h3.elementor-widget-heading,
body[class*=elementor-kit] .h3.elementor-widget-heading {
  color: #34576F !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.375;
  margin-top: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h3.elementor-widget-heading, body[class*=elementor-kit] h3.elementor-widget-heading,
body[class*=elementor-kit] .h3.elementor-widget-heading,
body[class*=elementor-kit] .h3.elementor-widget-heading {
    font-size: 22px;
    line-height: 1.3076923077;
  }
}
body[class*=elementor-kit] h4,
body[class*=elementor-kit] .h4 {
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.005em;
  margin-top: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h4,
body[class*=elementor-kit] .h4 {
    font-size: 20px;
    line-height: 1.5;
  }
}
body[class*=elementor-kit] h4.elementor-widget-heading, body[class*=elementor-kit] h4.elementor-widget-heading,
body[class*=elementor-kit] .h4.elementor-widget-heading,
body[class*=elementor-kit] .h4.elementor-widget-heading {
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.005em;
  margin-top: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h4.elementor-widget-heading, body[class*=elementor-kit] h4.elementor-widget-heading,
body[class*=elementor-kit] .h4.elementor-widget-heading,
body[class*=elementor-kit] .h4.elementor-widget-heading {
    font-size: 20px;
    line-height: 1.5;
  }
}
body[class*=elementor-kit] h5,
body[class*=elementor-kit] .h5 {
  color: #34576F !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4285714286;
  text-transform: uppercase;
  margin-top: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h5,
body[class*=elementor-kit] .h5 {
    font-size: 16px;
    line-height: 1.625;
  }
}
body[class*=elementor-kit] h5.elementor-widget-heading, body[class*=elementor-kit] h5.elementor-widget-heading,
body[class*=elementor-kit] .h5.elementor-widget-heading,
body[class*=elementor-kit] .h5.elementor-widget-heading {
  color: #34576F !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4285714286;
  text-transform: uppercase;
  margin-top: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] h5.elementor-widget-heading, body[class*=elementor-kit] h5.elementor-widget-heading,
body[class*=elementor-kit] .h5.elementor-widget-heading,
body[class*=elementor-kit] .h5.elementor-widget-heading {
    font-size: 16px;
    line-height: 1.625;
  }
}
body[class*=elementor-kit] button, body[class*=elementor-kit] .btn, body[class*=elementor-kit] p {
  margin-top: 0;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] button, body[class*=elementor-kit] .btn, body[class*=elementor-kit] p {
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] button, body[class*=elementor-kit] .btn, body[class*=elementor-kit] p {
    margin-bottom: 25px;
  }
}
body[class*=elementor-kit] a,
body[class*=elementor-kit] .elementor p a {
  color: #B01F24;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  text-decoration: underline;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] a:hover,
body[class*=elementor-kit] .elementor p a:hover {
    color: #34576F;
  }
}
body[class*=elementor-kit] a.btn,
body[class*=elementor-kit] button,
body[class*=elementor-kit] .btn,
body[class*=elementor-kit] .elementor-button-link,
body[class*=elementor-kit] .elementor-button {
  color: #FFFFFF;
  background: #B01F24;
  border: 1px solid #B01F24;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.3571428571;
  border-radius: 0;
  font-size: 14px;
  padding: 10px 20px;
  text-transform: uppercase;
  -webkit-appearance: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
body[class*=elementor-kit] a.btn[disabled],
body[class*=elementor-kit] button[disabled],
body[class*=elementor-kit] .btn[disabled],
body[class*=elementor-kit] .elementor-button-link[disabled],
body[class*=elementor-kit] .elementor-button[disabled] {
  background: #CFCEC8;
  border-color: #CFCEC8;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] a.btn,
body[class*=elementor-kit] button,
body[class*=elementor-kit] .btn,
body[class*=elementor-kit] .elementor-button-link,
body[class*=elementor-kit] .elementor-button {
    font-size: 16px;
    line-height: 1.1875;
  }
  body[class*=elementor-kit] a.btn:hover,
body[class*=elementor-kit] button:hover,
body[class*=elementor-kit] .btn:hover,
body[class*=elementor-kit] .elementor-button-link:hover,
body[class*=elementor-kit] .elementor-button:hover {
    color: #B01F24;
    background: #FFFFFF;
  }
  body[class*=elementor-kit] a.btn:hover[disabled],
body[class*=elementor-kit] button:hover[disabled],
body[class*=elementor-kit] .btn:hover[disabled],
body[class*=elementor-kit] .elementor-button-link:hover[disabled],
body[class*=elementor-kit] .elementor-button:hover[disabled] {
    background: #CFCEC8;
  }
}
body[class*=elementor-kit] a.btn.btn-secondary,
body[class*=elementor-kit] button.btn-secondary,
body[class*=elementor-kit] .btn.btn-secondary,
body[class*=elementor-kit] .elementor-button-link.btn-secondary,
body[class*=elementor-kit] .elementor-button.btn-secondary {
  color: #B01F24;
  background: transparent;
  border: none;
  border-top: 1px solid #B01F24;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] a.btn.btn-secondary:hover,
body[class*=elementor-kit] button.btn-secondary:hover,
body[class*=elementor-kit] .btn.btn-secondary:hover,
body[class*=elementor-kit] .elementor-button-link.btn-secondary:hover,
body[class*=elementor-kit] .elementor-button.btn-secondary:hover {
    border-top: 1px solid #34576F;
    color: #34576F;
  }
}
body[class*=elementor-kit] .btn-secondary a,
body[class*=elementor-kit] .btn-secondary button,
body[class*=elementor-kit] .btn-secondary .btn,
body[class*=elementor-kit] .btn-secondary .elementor-button-link,
body[class*=elementor-kit] .btn-secondary .elementor-button {
  color: #B01F24;
  background: transparent;
  border: none;
  border-top: 1px solid #B01F24;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .btn-secondary a:hover,
body[class*=elementor-kit] .btn-secondary button:hover,
body[class*=elementor-kit] .btn-secondary .btn:hover,
body[class*=elementor-kit] .btn-secondary .elementor-button-link:hover,
body[class*=elementor-kit] .btn-secondary .elementor-button:hover {
    border-top: 1px solid #34576F;
    color: #34576F;
  }
}
body[class*=elementor-kit] p:last-child {
  margin-bottom: 0;
}
body[class*=elementor-kit] .text-uppercase {
  text-transform: uppercase;
}
body[class*=elementor-kit] .text-center {
  text-align: center;
}
body[class*=elementor-kit] .text-no-wrap {
  white-space: nowrap;
}
body[class*=elementor-kit] .small-text {
  font-size: 12px;
}

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;
}
.nice-select:hover {
  border-color: #dbdbdb;
}
.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #9F9F9F;
}
.nice-select:after {
  border-bottom: 2px solid #9F9F9F;
  border-right: 2px solid #9F9F9F;
  content: "";
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #ededed;
  color: #9F9F9F;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #d2d2d2;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #9F9F9F;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}
.no-csspointerevents .nice-select.open .list {
  display: block;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-height: 100vh;
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}
@media (min-width: 1024px) {
  body {
    font-size: 17px;
    line-height: 1.6470588235;
  }
}
body > .elementor {
  overflow: auto;
  overflow-x: hidden;
}
@media (min-width: 1024px) {
  body.menu-opened:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #34576F;
    opacity: 0.75;
    z-index: 1000;
  }
}

img {
  width: 100%;
  max-width: 100%;
}
img.rounded {
  border-radius: 50%;
}

@media (max-width: 768px) {
  body[class*=elementor-kit] .add-padding-right-mobile {
    padding-right: 15px;
  }
}
@media (max-width: 768px) {
  body[class*=elementor-kit] .hide-on-mobile {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  body[class*=elementor-kit] .hide-on-tablet {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1500px) {
  body[class*=elementor-kit] .hide-on-laptop {
    display: none;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] .hide-on-desktop {
    display: none;
  }
}
@media (max-width: 768px) {
  body[class*=elementor-kit] .display-block-on-mobile {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  body[class*=elementor-kit] .display-block-on-tablet {
    display: block;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .display-block-on-laptop {
    display: block;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] .display-block-on-desktop {
    display: block;
  }
}
body[class*=elementor-kit] .height-1 {
  height: 1px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .indent-on-laptop {
    display: inline-block;
    width: 100px;
  }
}
@media (max-width: 768px) {
  body[class*=elementor-kit] .force-fullwidth-columns-mobile .elementor-column {
    width: 100% !important;
    min-width: 100% !important;
  }
}

body[class*=elementor-kit] {
  /* what is this? */
}
body[class*=elementor-kit] .elementor-column-gap-default > .elementor-column > .elementor-element-populated {
  padding: 0;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .elementor-column-gap-default > .elementor-column > .elementor-element-populated {
    padding: 0 10px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-column-gap-default > .elementor-column > .elementor-element-populated {
    padding: 0 15px;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] .elementor-column-gap-default > .elementor-column > .elementor-element-populated {
    padding: 0 20px;
  }
}
body[class*=elementor-kit] .elementor-column-gap-default > .elementor-col-50 {
  margin-bottom: 15px;
}
body[class*=elementor-kit] section.elementor-top-section {
  margin-bottom: 100px;
}
body[class*=elementor-kit] section.elementor-top-section > .elementor-column-gap-default > .elementor-column > .elementor-element-populated {
  padding-left: 0;
  padding-right: 0;
}
body[class*=elementor-kit] header .elementor-section.elementor-section-boxed > .elementor-container,
body[class*=elementor-kit] footer .elementor-section.elementor-section-boxed > .elementor-container,
body[class*=elementor-kit] .elementor-top-section.elementor-section.elementor-section-boxed > .elementor-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 600px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] header .elementor-section.elementor-section-boxed > .elementor-container,
body[class*=elementor-kit] footer .elementor-section.elementor-section-boxed > .elementor-container,
body[class*=elementor-kit] .elementor-top-section.elementor-section.elementor-section-boxed > .elementor-container {
    padding: 0;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] header .elementor-section.elementor-section-boxed > .elementor-container,
body[class*=elementor-kit] footer .elementor-section.elementor-section-boxed > .elementor-container,
body[class*=elementor-kit] .elementor-top-section.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1000px;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] header .elementor-section.elementor-section-boxed > .elementor-container,
body[class*=elementor-kit] footer .elementor-section.elementor-section-boxed > .elementor-container,
body[class*=elementor-kit] .elementor-top-section.elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1200px;
  }
}
body[class*=elementor-kit] section.elementor-section-stretched.elementor-top-section > .elementor-container {
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] section.elementor-section-stretched.elementor-top-section > .elementor-container {
    padding-left: 0;
    padding-right: 0;
  }
}
body[class*=elementor-kit] section.background-cream {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] section.background-cream {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
body[class*=elementor-kit] section.background-cream + .background-cream {
  margin-top: -100px;
  padding-top: 100px;
}
body[class*=elementor-kit] section.background-cream:last-child {
  margin-bottom: 0;
}

body[class*=elementor-kit] header.elementor-section.elementor-section-stretched {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, 0.05);
}

body[class*=elementor-kit] div[data-elementor-type=footer] {
  border-top: 1px solid #B01F24;
  background: #FFFFFF;
  font-size: 14px;
  line-height: 1.4;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] {
    font-size: 16px;
  }
}
body[class*=elementor-kit] div[data-elementor-type=footer] section.elementor-top-section {
  margin-bottom: 15px;
}
body[class*=elementor-kit] div[data-elementor-type=footer] ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
body[class*=elementor-kit] div[data-elementor-type=footer] ul li {
  padding: 0;
  margin: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] ul li {
    margin: 0 0 14px 0;
  }
}
body[class*=elementor-kit] div[data-elementor-type=footer] a {
  font-size: 14px;
  text-decoration: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] a {
    font-size: 16px;
  }
}
body[class*=elementor-kit] div[data-elementor-type=footer] a[href^=mailto] {
  color: #B01F24 !important;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] a:hover {
    text-decoration: underline;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] .small-text {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
}
body[class*=elementor-kit] div[data-elementor-type=footer] .small-text a {
  font-size: 12px;
  color: #66717D;
  text-decoration: underline;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] .small-text a:hover {
    color: #B01F24;
  }
}
body[class*=elementor-kit] div[data-elementor-type=footer] .elementor-button {
  font-size: 14px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] .elementor-button {
    font-size: 16px;
  }
}
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-column-gap-default > .elementor-column > .elementor-element-populated {
  padding: 0;
}
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-icon-list-item span,
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-widget.elementor-align-left .elementor-icon-list-item span {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-icon-list-item a,
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-widget.elementor-align-left .elementor-icon-list-item a {
  background: #66717D;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #FFFFFF !important;
  justify-content: center;
  text-align: center;
  display: inline-block;
  line-height: 30px;
}
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-icon-list-item a:hover,
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-widget.elementor-align-left .elementor-icon-list-item a:hover {
  background: #B01F24;
  color: #FFFFFF !important;
  text-decoration: none;
}
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-widget .elementor-icon-list-icon + .elementor-icon-list-text {
  display: none;
}
body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-widget {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] .footer-social-column .elementor-widget {
    padding-bottom: 0;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] #footer-logo {
    padding-right: 16px;
  }
}
@media (min-width: 768px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] #footer-logo .elementor-widget-container > a {
    position: relative;
  }
}
@media (min-width: 768px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] #footer-logo .elementor-widget-container > a:after {
    content: "";
    width: 1px;
    height: 40px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(20px, -50%);
    border-right: 1px solid #66717D;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] #footer-logo .elementor-widget-container > a:after {
    height: 80px;
    transform: translate(70px, -50%);
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] div[data-elementor-type=footer] #footer-logo .elementor-widget-container > a:after {
    transform: translate(30px, -50%);
  }
}

body[class*=elementor-kit]:not(.elementor-editor-active) .cvb-fullwidth-callout .copy {
  transform: translate(0, -50px);
}
body[class*=elementor-kit]:not(.elementor-editor-active) .cvb-fullwidth-callout .bg-img {
  transform: translate(0, 50px);
}
body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .elementor-accordion-item,
body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h1, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h2, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h3, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h4, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h5, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h1, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h2, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h3, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h4, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h5,
body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) p, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) ul li, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) button, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .btn {
  opacity: 0;
  transform: translate(0, 10px);
  transition: all 0.65s ease;
}
body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .elementor-accordion-item.revealed,
body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h1.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h2.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h3.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h4.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) h5.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h1.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h2.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h3.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h4.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .h5.revealed,
body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) p.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) ul li.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) button.revealed, body[class*=elementor-kit]:not(.elementor-editor-active) > div:not(.elementor-location-header):not(.elementor-popup-modal) .btn.revealed {
  opacity: 1;
  transform: translate(0);
}

.background-cream {
  background: #EAE9E1;
}

.force-fullwidth-background-cream {
  background: #EAE9E1;
  position: relative;
}
.force-fullwidth-background-cream:before {
  content: "";
  position: absolute;
  background: #EAE9E1;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

body[class*=elementor-kit] {
  /* gravity forms overrides */
}
body[class*=elementor-kit] form .gform_wrapper.gravity-theme .gfield_label,
body[class*=elementor-kit] form label,
body[class*=elementor-kit] form legend {
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.005em;
  margin-top: 0;
  letter-spacing: 0.005em;
  text-transform: none;
  color: #34576F !important;
  padding-bottom: 15px !important;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form .gform_wrapper.gravity-theme .gfield_label,
body[class*=elementor-kit] form label,
body[class*=elementor-kit] form legend {
    font-size: 20px;
    line-height: 1.5;
  }
}
body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea,
body[class*=elementor-kit] form .pp-gravity-form .gfield select,
body[class*=elementor-kit] form input,
body[class*=elementor-kit] form textarea,
body[class*=elementor-kit] form select,
body[class*=elementor-kit] form .nice-select {
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  padding: 15px !important;
  background-color: #EAE9E1 !important;
  border: none !important;
  border-radius: 0;
  color: #34576F !important;
  font-family: semplicitapro, sans-serif !important;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea,
body[class*=elementor-kit] form .pp-gravity-form .gfield select,
body[class*=elementor-kit] form input,
body[class*=elementor-kit] form textarea,
body[class*=elementor-kit] form select,
body[class*=elementor-kit] form .nice-select {
    font-size: 17px;
    line-height: 1.6470588235;
  }
}
body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])::placeholder,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea::placeholder,
body[class*=elementor-kit] form .pp-gravity-form .gfield select::placeholder,
body[class*=elementor-kit] form input::placeholder,
body[class*=elementor-kit] form textarea::placeholder,
body[class*=elementor-kit] form select::placeholder,
body[class*=elementor-kit] form .nice-select::placeholder {
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  font-family: semplicitapro, sans-serif !important;
  color: #9F9F9F !important;
  opacity: 1 !important;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])::placeholder,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea::placeholder,
body[class*=elementor-kit] form .pp-gravity-form .gfield select::placeholder,
body[class*=elementor-kit] form input::placeholder,
body[class*=elementor-kit] form textarea::placeholder,
body[class*=elementor-kit] form select::placeholder,
body[class*=elementor-kit] form .nice-select::placeholder {
    font-size: 17px;
    line-height: 1.6470588235;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea,
body[class*=elementor-kit] form .pp-gravity-form .gfield select,
body[class*=elementor-kit] form input,
body[class*=elementor-kit] form textarea,
body[class*=elementor-kit] form select,
body[class*=elementor-kit] form .nice-select {
    padding: 15px 40px !important;
  }
}
body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=checkbox], body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=radio],
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=checkbox],
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=radio],
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=checkbox],
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=radio],
body[class*=elementor-kit] form input[type=checkbox],
body[class*=elementor-kit] form input[type=radio],
body[class*=elementor-kit] form textarea[type=checkbox],
body[class*=elementor-kit] form textarea[type=radio],
body[class*=elementor-kit] form select[type=checkbox],
body[class*=elementor-kit] form select[type=radio],
body[class*=elementor-kit] form .nice-select[type=checkbox],
body[class*=elementor-kit] form .nice-select[type=radio] {
  accent-color: #B01F24;
  padding: 0;
}
body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=checkbox] + label, body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=radio] + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=checkbox] + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=radio] + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=checkbox] + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=radio] + label,
body[class*=elementor-kit] form input[type=checkbox] + label,
body[class*=elementor-kit] form input[type=radio] + label,
body[class*=elementor-kit] form textarea[type=checkbox] + label,
body[class*=elementor-kit] form textarea[type=radio] + label,
body[class*=elementor-kit] form select[type=checkbox] + label,
body[class*=elementor-kit] form select[type=radio] + label,
body[class*=elementor-kit] form .nice-select[type=checkbox] + label,
body[class*=elementor-kit] form .nice-select[type=radio] + label {
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=checkbox] + label, body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=radio] + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=checkbox] + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=radio] + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=checkbox] + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=radio] + label,
body[class*=elementor-kit] form input[type=checkbox] + label,
body[class*=elementor-kit] form input[type=radio] + label,
body[class*=elementor-kit] form textarea[type=checkbox] + label,
body[class*=elementor-kit] form textarea[type=radio] + label,
body[class*=elementor-kit] form select[type=checkbox] + label,
body[class*=elementor-kit] form select[type=radio] + label,
body[class*=elementor-kit] form .nice-select[type=checkbox] + label,
body[class*=elementor-kit] form .nice-select[type=radio] + label {
    font-size: 17px;
    line-height: 1.6470588235;
  }
}
body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=checkbox]:checked + label, body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=radio]:checked + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=checkbox]:checked + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=radio]:checked + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=checkbox]:checked + label,
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=radio]:checked + label,
body[class*=elementor-kit] form input[type=checkbox]:checked + label,
body[class*=elementor-kit] form input[type=radio]:checked + label,
body[class*=elementor-kit] form textarea[type=checkbox]:checked + label,
body[class*=elementor-kit] form textarea[type=radio]:checked + label,
body[class*=elementor-kit] form select[type=checkbox]:checked + label,
body[class*=elementor-kit] form select[type=radio]:checked + label,
body[class*=elementor-kit] form .nice-select[type=checkbox]:checked + label,
body[class*=elementor-kit] form .nice-select[type=radio]:checked + label {
  font-weight: 600;
}
body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=submit],
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=submit],
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=submit],
body[class*=elementor-kit] form input[type=submit],
body[class*=elementor-kit] form textarea[type=submit],
body[class*=elementor-kit] form select[type=submit],
body[class*=elementor-kit] form .nice-select[type=submit] {
  color: #FFFFFF;
  background: #B01F24;
  border: 1px solid #B01F24;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.3571428571;
  border-radius: 0;
  font-size: 14px;
  padding: 10px 20px;
  text-transform: uppercase;
  -webkit-appearance: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: #FFFFFF !important;
  padding: 10px 20px !important;
  border: 1px solid #B01F24 !important;
  background-color: #B01F24 !important;
}
body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=submit][disabled],
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=submit][disabled],
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=submit][disabled],
body[class*=elementor-kit] form input[type=submit][disabled],
body[class*=elementor-kit] form textarea[type=submit][disabled],
body[class*=elementor-kit] form select[type=submit][disabled],
body[class*=elementor-kit] form .nice-select[type=submit][disabled] {
  background: #CFCEC8;
  border-color: #CFCEC8;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=submit],
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=submit],
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=submit],
body[class*=elementor-kit] form input[type=submit],
body[class*=elementor-kit] form textarea[type=submit],
body[class*=elementor-kit] form select[type=submit],
body[class*=elementor-kit] form .nice-select[type=submit] {
    font-size: 16px;
    line-height: 1.1875;
  }
  body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=submit]:hover,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=submit]:hover,
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=submit]:hover,
body[class*=elementor-kit] form input[type=submit]:hover,
body[class*=elementor-kit] form textarea[type=submit]:hover,
body[class*=elementor-kit] form select[type=submit]:hover,
body[class*=elementor-kit] form .nice-select[type=submit]:hover {
    color: #B01F24;
    background: #FFFFFF;
  }
  body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=submit]:hover[disabled],
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=submit]:hover[disabled],
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=submit]:hover[disabled],
body[class*=elementor-kit] form input[type=submit]:hover[disabled],
body[class*=elementor-kit] form textarea[type=submit]:hover[disabled],
body[class*=elementor-kit] form select[type=submit]:hover[disabled],
body[class*=elementor-kit] form .nice-select[type=submit]:hover[disabled] {
    background: #CFCEC8;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form .pp-gravity-form .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file])[type=submit]:hover,
body[class*=elementor-kit] form .pp-gravity-form .gfield textarea[type=submit]:hover,
body[class*=elementor-kit] form .pp-gravity-form .gfield select[type=submit]:hover,
body[class*=elementor-kit] form input[type=submit]:hover,
body[class*=elementor-kit] form textarea[type=submit]:hover,
body[class*=elementor-kit] form select[type=submit]:hover,
body[class*=elementor-kit] form .nice-select[type=submit]:hover {
    color: #B01F24 !important;
    border: 1px solid #B01F24 !important;
    background-color: #FFFFFF !important;
  }
}
body[class*=elementor-kit] form button {
  color: #FFFFFF;
  background: #B01F24;
  border: 1px solid #B01F24;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.3571428571;
  border-radius: 0;
  font-size: 14px;
  padding: 10px 20px;
  text-transform: uppercase;
  -webkit-appearance: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px !important;
  color: #FFFFFF !important;
  border: 1px solid #B01F24 !important;
}
body[class*=elementor-kit] form button[disabled] {
  background: #CFCEC8;
  border-color: #CFCEC8;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form button {
    font-size: 16px;
    line-height: 1.1875;
  }
  body[class*=elementor-kit] form button:hover {
    color: #B01F24;
    background: #FFFFFF;
  }
  body[class*=elementor-kit] form button:hover[disabled] {
    background: #CFCEC8;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form button[type=submit]:hover {
    color: #B01F24 !important;
    border: 1px solid #B01F24 !important;
  }
}
body[class*=elementor-kit] form .elementor-field-group {
  margin-bottom: 40px !important;
}
body[class*=elementor-kit] form .elementor-select-wrapper .select-caret-down-wrapper {
  inset-inline-end: 30px;
}
body[class*=elementor-kit] form .nice-select {
  height: auto;
  width: 100%;
}
body[class*=elementor-kit] form .nice-select:after {
  border-bottom: none;
  border-top: 11px solid #B01F24;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  width: 11px;
  transform: none;
  right: 30px;
}
body[class*=elementor-kit] form .nice-select .list {
  width: 100%;
  border: none;
  margin: 0;
  max-height: 300px;
  overflow: hidden;
  z-index: 99999999999;
  position: absolute;
  overflow-y: scroll;
}
body[class*=elementor-kit] form .nice-select .list::-webkit-scrollbar, body[class*=elementor-kit] form .nice-select .list::-webkit-scrollbar-track, body[class*=elementor-kit] form .nice-select .list::-webkit-scrollbar-thumb {
  border-radius: 12px;
}
body[class*=elementor-kit] form .nice-select .list::-webkit-scrollbar {
  width: 4px;
}
body[class*=elementor-kit] form .nice-select .list::-webkit-scrollbar-track {
  background-color: #EAE9E1;
}
body[class*=elementor-kit] form .nice-select .list::-webkit-scrollbar-thumb {
  background-color: #9D9D9A;
}
body[class*=elementor-kit] form .nice-select .list li {
  background: #EAE9E1;
  border: none;
  border-bottom: 1px solid #CFCEC8;
  padding: 15px;
}
body[class*=elementor-kit] form .nice-select .list li:empty {
  display: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] form .nice-select .list li {
    padding: 15px 40px;
  }
}
body[class*=elementor-kit] form .nice-select .list li:hover {
  color: #B01F24;
}
body[class*=elementor-kit] form .nice-select .list li.selected {
  background-color: #EAE9E1;
}
body[class*=elementor-kit] .background-cream form input,
body[class*=elementor-kit] .background-cream form textarea,
body[class*=elementor-kit] .background-cream form select,
body[class*=elementor-kit] .background-cream form .nice-select {
  background-color: #FFFFFF !important;
}
body[class*=elementor-kit] .background-cream form .nice-select .list li {
  background: #FFFFFF;
}
body[class*=elementor-kit] .pp-gravity-form .gform_wrapper .pp-gf-select-custom:after {
  display: none;
}
body[class*=elementor-kit] .gform_wrapper.gravity-theme .gfield-choice-input + label {
  padding-bottom: 0 !important;
}
body[class*=elementor-kit] .pp-gravity-form .gform_footer input[type=submit],
body[class*=elementor-kit] .pp-gravity-form .gform_page_footer input[type=submit] {
  width: 100% !important;
}

body[class*=elementor-kit]:not(.elementor-editor-active) {
  background: #294558;
  /*.preloader {
    position: fixed;
    top: 50%;
    left:50%;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #34576f;
    color: #34576f;
    transform-origin: 5px 15px;
    transform:translate(-50%,-50%);
    animation: preloader 4s infinite linear;
    &::before, 
    &::after {
  	  content: '';
  	  display: inline-block;
  	  position: absolute;
  	}
  	&::before {
  	  left: -8.66px;
  	  top: 15px;
  	  width: 10px;
  	  height: 10px;
  	  border-radius: 5px;
  	  background-color: #5e7b9a;
  	  color: #5e7b9a;
  	}
  	&::after {
  	  left: 8.66px;
  	  top: 15px;
  	  width: 10px;
  	  height: 10px;
  	  border-radius: 5px;
  	  background-color: #294558;
  	  color: #294558;
  	}
  }

  @keyframes preloader {
    0% {
      transform: rotateZ(0deg) translate3d(0, 0, 0);
    }
    100% {
      transform: rotateZ(720deg) translate3d(0, 0, 0);
    }
  }*/
}
body[class*=elementor-kit]:not(.elementor-editor-active) > .elementor {
  opacity: 0;
  transition: all 0.65s ease-out;
}
body[class*=elementor-kit]:not(.elementor-editor-active) #preloader-container {
  position: absolute;
  width: 100px;
  height: 100px;
  margin: auto;
  transform: rotate(45deg);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
body[class*=elementor-kit]:not(.elementor-editor-active).loaded {
  background: #fff;
}
body[class*=elementor-kit]:not(.elementor-editor-active).loaded > .elementor {
  opacity: 1;
}
body[class*=elementor-kit]:not(.elementor-editor-active).loaded #preloader-container {
  opacity: 0;
}

body[class*=elementor-kit] {
  /* home page particles */
}
body[class*=elementor-kit] .cvb-home-page-hero {
  margin-bottom: 0 !important;
}
body[class*=elementor-kit] .cvb-home-page-hero h1 {
  z-index: 2;
  font-weight: 200;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-home-page-hero h1 {
    font-size: 75px;
  }
}
body[class*=elementor-kit] .cvb-home-page-hero h1 span.h2 {
  color: #fff !important;
  display: block;
  font-weight: 400;
  text-transform: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-home-page-hero h1 span.h2 {
    margin-left: 110px;
    max-width: 600px;
  }
}
body[class*=elementor-kit] .cvb-home-page-tabs {
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-home-page-tabs {
    margin-top: -200px;
  }
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper {
    height: 200px;
  }
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title {
    height: 200px;
  }
  body[class*=elementor-kit] .cvb-home-page-tabs.background-cream {
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-home-page-tabs {
    margin-top: -152px;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper {
    height: 152px;
  }
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title {
    height: 152px;
  }
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title {
  font-size: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 500;
  line-height: 25px;
  position: relative;
  padding: 40px 15px;
  border-width: 2px;
  background: #34576F;
  margin-bottom: 0;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title:before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title:before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title:before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title:before {
  background: #34576F;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title {
    padding: 40px;
  }
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+2),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title:nth-child(4n+2),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title:nth-child(4n+2),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title:nth-child(4n+2) {
  background: #5E7B9A;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+2):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title:nth-child(4n+2):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title:nth-child(4n+2):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title:nth-child(4n+2):before {
  background: #5E7B9A;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+3),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title:nth-child(4n+3),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title:nth-child(4n+3),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title:nth-child(4n+3) {
  background: #707070;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+3):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title:nth-child(4n+3):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title:nth-child(4n+3):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title:nth-child(4n+3):before {
  background: #707070;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+4),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title:nth-child(4n+4),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title:nth-child(4n+4),
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title:nth-child(4n+4) {
  background: #9D9D9A;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+4):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title:nth-child(4n+4):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title:nth-child(4n+4):before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title:nth-child(4n+4):before {
  background: #9D9D9A;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title.elementor-active,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title.elementor-active,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title.elementor-active,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title.elementor-active {
  background: #FFFFFF;
  color: #B01F24;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-title.elementor-active:before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title.elementor-active:before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-title.elementor-active:before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title.elementor-active:before {
  background: #FFFFFF;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title:before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title:before {
  color: #FFFFFF;
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-mobile-title.elementor-active:before,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title.elementor-active:before {
  color: #B01F24;
}
@media (max-width: 768px) {
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-content,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-content {
    margin-bottom: 0;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-content,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-content {
    padding: 40px 0;
  }
}
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-content.elementor-active,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-content.elementor-active {
  border-top: 1px solid #B01F24;
}
@media (max-width: 768px) {
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-content.elementor-active,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-content.elementor-active {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-wrapper .elementor-tab-content.elementor-active,
body[class*=elementor-kit] .cvb-home-page-tabs .elementor-tabs-content-wrapper .elementor-tab-content.elementor-active {
    border-top: none;
  }
}
body[class*=elementor-kit] #particle-container {
  position: relative;
}
body[class*=elementor-kit] #particle-container:before {
  content: "";
  position: absolute;
  background: url(../img/overlay.webp) no-repeat center center;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.64;
  z-index: 1;
}
body[class*=elementor-kit] #particle-container canvas {
  z-index: 1;
  position: relative;
  max-height: 90vh !important;
}
@media (max-width: 767px) {
  body[class*=elementor-kit] #particle-container canvas {
    height: 300px !important;
    opacity: 0 !important;
  }
}

@media (min-width: 1024px) {
  body[class*=elementor-kit] #secondary-page-header {
    min-height: 500px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] #secondary-page-header > .elementor-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
body[class*=elementor-kit] #secondary-page-header h1 {
  margin-bottom: -8px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] #secondary-page-header h1 {
    margin-bottom: -10px;
  }
}
body[class*=elementor-kit] .post-excerpt {
  font-size: 16px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .post-excerpt {
    font-size: 18px;
  }
}

/* override for 22 Jumps page */
body[class*=elementor-kit].page-id-10590 #secondary-page-header h1,
body[class*=elementor-kit].page-id-243 #secondary-page-header h1 {
  margin-bottom: 13px;
}

body[class*=elementor-kit] #header-icon {
  position: relative;
}
body[class*=elementor-kit] #header-icon:before, body[class*=elementor-kit] #header-icon:after {
  content: "";
  background: #B01F24;
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 5px;
  transform: translate(3px, -5px);
}
body[class*=elementor-kit] #header-icon:after {
  width: 5px;
  height: 120px;
}
body[class*=elementor-kit] #header-icon img {
  width: 100%;
}

body[class*=elementor-kit].page-template-template-search {
  background: #EAE9E1;
}
body[class*=elementor-kit].page-template-template-search .search-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 600px;
  margin-bottom: 100px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit].page-template-template-search .search-container {
    padding: 0;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container {
    max-width: 1000px;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit].page-template-template-search .search-container {
    max-width: 1200px;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row {
    flex-direction: row;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column {
  width: 100%;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container {
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 15px;
  position: absolute;
  z-index: 100;
  top: 300px;
  width: calc(100% - 60px);
  left: 15px;
  display: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container.active {
  display: block;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container * {
  opacity: 1;
  transform: translate(0);
}
@media (min-width: 768px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container {
    width: 570px;
    left: 50%;
    transform: translate(calc(-50% - 15px), 0);
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container {
    padding: 0 40px;
    display: block;
    margin-top: 72px;
    margin-bottom: 80px;
    position: relative;
    z-index: initial;
    width: 25%;
    margin-right: 30px;
    transform: none;
    left: auto;
    top: auto;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container {
    margin-right: 40px;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container h2 {
  margin-top: 40px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .close-filters {
  background: #B01F24 url(../img/icon-close-white.png) no-repeat center center;
  background-size: 12px auto;
  color: transparent;
  font-size: 0;
  height: 30px;
  width: 30px;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, 0);
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .close-filters {
    display: none;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group {
  margin-bottom: 40px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group p {
  border-bottom: 1px solid #34576F;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group input[type=radio],
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group input[type=checkbox] {
  accent-color: #B01F24;
  padding: 0;
  margin-right: 10px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group input[type=radio] + span,
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group input[type=checkbox] + span {
  color: #66717D !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group input[type=radio] + span,
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group input[type=checkbox] + span {
    font-size: 17px;
    line-height: 1.6470588235;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group input[type=radio]:checked + span,
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group input[type=checkbox]:checked + span {
  font-weight: 600;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.filters-container .filter-group .filter-item-page {
  display: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container {
    width: calc(75% - 30px);
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container {
    width: calc(75% - 40px);
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header {
  font-size: 14px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding: 15px 0;
}
@media (min-width: 768px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header {
    padding: 0;
    padding-left: 57px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header {
    padding-left: 0;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .open-filters {
  background: #B01F24 url(../img/icon-filters.png) no-repeat center center;
  background-size: 19px auto;
  color: transparent;
  font-size: 0;
  height: 42px;
  width: 42px;
  position: absolute;
  top: 15px;
  left: 0;
}
@media (min-width: 768px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .open-filters {
    top: 0;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .open-filters {
    display: none;
  }
}
@media (min-width: 768px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-summary {
    width: 50%;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort {
    width: 50%;
    margin: 0;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort label {
  height: 42px;
  line-height: 42px;
  margin-right: 10px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort .nice-select {
  border-radius: 0;
  font-size: 14px;
  padding-left: 10px;
  min-width: 105px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort .nice-select.open {
  border-color: #CFCEC8;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort .nice-select:after {
  border-bottom: none;
  border-top: 6px solid #B01F24;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  transform: none;
  right: 10px;
  width: 1px;
  height: 6px;
  margin-top: -3px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort .nice-select .list {
  width: 100%;
  border: 1px solid #CFCEC8;
  border-radius: 0;
  margin: 0;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort .nice-select .list li {
  background: #FFFFFF;
  border: none;
  border-bottom: 1px solid #CFCEC8;
  padding: 7.5px;
  min-height: 20px;
  line-height: 2;
  font-size: 13px;
  opacity: 1;
  transform: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort .nice-select .list li:hover {
  color: #B01F24;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-header .search-sort .nice-select .list li.selected {
  background-color: #EAE9E1;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .no-results-message {
  display: none;
  padding: 15px 0;
  text-align: center;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .no-results-message {
    text-align: left;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .no-results-message.active {
  display: block;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result {
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 15px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result * {
  opacity: 1;
  transform: translate(0);
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result {
    padding: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result .result-content {
    width: 60%;
    min-width: 60%;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result .result-content .eyebrow,
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result .result-content h3 {
  margin-top: 0;
  margin-bottom: 15px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result .result-content .search-result-excerpt {
  margin-top: 0;
  margin-bottom: 30px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result .result-content .btn {
  margin-bottom: 0;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result .result-thumbnail {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result .result-thumbnail {
    width: 40%;
    min-width: 40%;
    min-height: 100%;
    margin-left: 15px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result.no-thumbnail .result-content {
    width: 100%;
    min-width: 100%;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-result.no-thumbnail .result-thumbnail {
  display: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination {
  display: flex;
  justify-content: space-between;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button {
  opacity: 1;
  transform: translate(0);
  background: transparent;
  padding: 0;
  color: #B01F24;
  border: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.inactive {
  color: #9F9F9F;
  cursor: default;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.previous-page, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.next-page {
  font-size: 0;
  margin-bottom: 0;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.previous-page svg, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.next-page svg {
  margin-top: 14px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.previous-page, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.next-page {
    font-size: inherit;
    height: 41px;
    line-height: 41px;
  }
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.previous-page svg, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.next-page svg {
    margin-top: 0;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.previous-page svg {
  margin-right: 5px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination button.next-page svg {
  margin-left: 5px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers {
  display: flex;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button {
  margin: 0 10px;
  position: relative;
  line-height: 41px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:hover {
  text-decoration: underline;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button.active {
  color: #34576F;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button.active:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  border: 2px solid #34576F;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button.active-sibling, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button.active, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button.active + button, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:last-child {
  display: inline-block !important;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button {
  display: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active-sibling:before {
  content: "…";
  font-size: 24px;
  display: inline-block;
  margin-right: 18px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active + button:after {
  content: "…";
  font-size: 24px;
  display: inline-block;
  margin-left: 18px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button:nth-last-child(-n+5) {
  display: inline-block;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button:nth-last-child(5):before {
  content: "…";
  font-size: 24px;
  display: inline-block;
  margin-right: 18px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button:nth-child(-n+2):before, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button:nth-child(-n+2):after, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button:nth-last-child(-n+2):before, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button:nth-last-child(-n+2):after, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active-sibling:nth-last-child(-n+4):before, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active-sibling:nth-last-child(-n+4):after {
  display: none !important;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8).active ~ button:nth-last-child(-n+5), body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active ~ button:nth-last-child(-n+5) {
  display: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8).active ~ button:nth-last-child(-n+5):before, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active ~ button:nth-last-child(-n+5):before {
  display: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8).active ~ button:nth-child(-n+5), body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active ~ button:nth-child(-n+5) {
  display: inline-block;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8).active ~ button:nth-child(-n+4):after, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active ~ button:nth-child(-n+4):after {
  display: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8).active ~ button:nth-child(5):after, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active ~ button:nth-child(5):after {
  content: "…";
  font-size: 24px;
  display: inline-block;
  margin-left: 18px;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8).active:before, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8).active:after, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active:before, body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:first-child:nth-last-child(n+8) ~ button.active:after {
  display: none;
}
@media (min-width: 768px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:nth-child(2), body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:nth-last-child(2), body[class*=elementor-kit].page-template-template-search .search-container .search-row .search-column.results-container .search-pagination .page-numbers button:nth-last-child(3) {
    display: inline-block !important;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .filters-overlay {
  background-color: #66717D;
  opacity: 0.87;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: none;
}
body[class*=elementor-kit].page-template-template-search .search-container .search-row .filters-overlay.active {
  display: block;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container .search-row .filters-overlay {
    display: none;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper {
  padding-top: 30px;
  margin-bottom: 30px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper {
    padding-top: 100px;
    margin-bottom: 100px;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form label {
  width: 100%;
  text-align: center;
  font-weight: bold;
}
body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form input[type=search] {
  background: #FFFFFF !important;
  padding-right: 98px !important;
  height: 50px;
  border-radius: 0;
  -webkit-appearance: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form input[type=search] {
    height: 58px;
  }
}
body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 18px;
  width: 18px;
  background-image: url(../img/icon-close.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px 18px;
}
body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form input[type=search]:focus-visible {
  outline: 2px solid #34576F;
}
body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form input[type=submit] {
  opacity: 0;
}
body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form .submit-wrapper {
  background: #B01F24 url(../img/icon-search.png) no-repeat center center;
  background-size: 20px auto;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit].page-template-template-search .search-container.search-form-wrapper form .submit-wrapper {
    background-size: 30px auto;
    width: 58px;
    height: 58px;
  }
}

body[class*=elementor-kit] .mobile-search-link {
  display: inline-block;
  float: right;
  margin: 15px 30px 0 0;
  width: 27px;
  height: 27px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .mobile-search-link {
    display: none;
  }
}
body[class*=elementor-kit] .header-newsletter-link {
  display: inline-block;
  float: right;
  margin: 15px 30px 0 0;
  width: 27px;
  height: 27px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .header-newsletter-link {
    display: none;
  }
}
body[class*=elementor-kit] button.open-menu {
  background: #B01F24 url(../img/icon-close.png) no-repeat center center;
  background-size: 13px auto;
  width: 35px;
  height: 35px;
  padding: 7px;
  box-sizing: border-box;
  float: right;
  margin: 10px 0;
  transition: none;
}
body[class*=elementor-kit] button.open-menu span {
  background: #FFFFFF;
  height: 2px;
  width: 100%;
  margin: 4px 0;
  display: block;
}
body[class*=elementor-kit] button.open-menu.active {
  background: #B01F24 url(../img/icon-close-white.png) no-repeat center center;
  background-size: 13px auto;
}
body[class*=elementor-kit] button.open-menu.active span {
  display: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] button.open-menu {
    display: none;
  }
}
body[class*=elementor-kit] .nav-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: #FFFFFF;
  transform: translate(100%, 0);
  transition: all 0.8s ease;
  overflow: auto;
}
body[class*=elementor-kit] .nav-container.opened {
  transform: translate(0);
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container {
    position: relative;
    width: 100%;
    height: auto;
    top: auto !important;
    transform: translate(0);
    overflow: visible;
  }
}
body[class*=elementor-kit] .nav-container .nav-title {
  width: 100%;
  background: #fff;
  position: relative;
  z-index: 100;
}
body[class*=elementor-kit] .nav-container .nav-title ul {
  margin: 0;
  padding: 0;
  background-color: #fff;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    justify-content: space-between;
    position: relative;
    z-index: 100;
  }
}
body[class*=elementor-kit] .nav-container .nav-title ul li {
  color: #B01F24;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
}
body[class*=elementor-kit] .nav-container .nav-title ul li.header-search {
  display: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul li.header-search {
    padding-right: 15px;
    margin-right: -15px;
    display: flex;
    align-items: center;
  }
  body[class*=elementor-kit] .nav-container .nav-title ul li.header-search input {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    height: 100%;
    width: 100vw;
    background: #EAE9E1;
    -webkit-appearance: none;
    border: none;
    outline: none;
    color: #66717D !important;
    font-family: semplicitapro, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.25;
    transition: all 0.3s ease-out;
    max-width: 0;
    padding: 0;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul li.header-search input {
    font-size: 17px;
    line-height: 1.6470588235;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul li.header-search input:focus {
    border: 1px solid #CFCEC8;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul li.header-search input.active {
    padding: 0 40px 0 15px;
    border-bottom: 3px solid #B01F24;
    max-width: 45vw;
    transition: all 0.65s ease-out;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul li.header-search img {
    position: relative;
    z-index: 1;
    cursor: pointer;
    width: 27px;
    height: 27px;
  }
}
body[class*=elementor-kit] .nav-container .nav-title ul li a {
  text-decoration: none;
  display: block;
  padding: 1em 1em 1em 1.2em;
  outline: none;
  font-size: 30px;
  color: #B01F24;
  border-bottom: 3px solid rgba(112, 112, 112, 0.15);
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul li a {
    border: none;
    font-size: 14px;
    color: #34576F;
    white-space: nowrap;
  }
  body[class*=elementor-kit] .nav-container .nav-title ul li a.active, body[class*=elementor-kit] .nav-container .nav-title ul li a:hover {
    color: #B01F24;
    background: #EFF1F2;
  }
}
body[class*=elementor-kit] .nav-container .nav-title ul li a span {
  font-size: 12px;
  float: right;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul li a span {
    display: none;
  }
}
body[class*=elementor-kit] .nav-container .nav-title ul li.donate-link-wrapper a {
  background: #B01F24;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.05em;
  border: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container .nav-title ul li.donate-link-wrapper {
    display: none;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer] {
  width: 100vw;
  height: 100%;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer] {
    height: auto;
    width: 100%;
    max-width: 1000px;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu {
  position: absolute;
  top: 0px;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #fff;
  padding: 15px;
  z-index: 200;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu {
    position: fixed;
    padding: 40px;
    top: 134px;
    height: auto;
    min-height: 75vh;
    width: 150vw;
    min-width: 150vw;
    background: #FFFFFF;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .back-link {
  text-transform: uppercase;
  color: #B01F24;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin: 15px 0;
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .back-link svg {
  margin-right: 5px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .back-link {
    display: none;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns {
  max-height: 100%;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns {
    max-width: 1000px;
    margin: 0 auto;
    overflow: auto;
    padding-right: 15px;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns::-webkit-scrollbar, body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns::-webkit-scrollbar-track, body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns::-webkit-scrollbar-thumb {
    border-radius: 12px;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns::-webkit-scrollbar {
    width: 4px;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns::-webkit-scrollbar-track {
    background-color: transparent;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns::-webkit-scrollbar-thumb {
    background-color: #CFCEC8;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns {
    max-width: 1200px;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns h2 {
  margin-top: 0;
  border-bottom: 1px solid #34576F;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns div[class^=column]:not(.column-2) {
    display: inline-block;
    float: left;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-1 {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-1 img {
    display: none;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-1 {
    margin-bottom: 0;
    width: 33%;
    margin-right: 40px;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-1 img {
    display: block;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper {
  background: #DBDBDB;
  padding: 15px;
  text-decoration: none;
  border-left: 2px solid #B01F24;
  margin-bottom: 5px;
  width: 100%;
  display: inline-block;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper {
    background: transparent;
    color: #34576F;
    border: none;
    padding: 0;
    margin: 0 0 30px 0;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper a {
  font-size: 15px;
  font-weight: bold;
  color: #B01F24;
  text-decoration: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper a {
    font-size: 18px;
    font-weight: 500;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper a:hover {
    text-decoration: underline;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper p {
  display: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper p {
    display: block;
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 {
    width: calc(67% - 40px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper {
    width: calc(50% - 20px);
    box-sizing: border-box;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-2 .link-wrapper:nth-child(odd) {
    margin-right: 40px;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 {
  width: 100%;
  position: relative;
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 button.previous-story, body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 button.next-story {
  display: none;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 button.previous-story, body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 button.next-story {
    display: inline-block;
    opacity: 1;
    transform: none;
    position: absolute;
    background: #B01F24 url(../img/arrow-left.svg) no-repeat center center;
    background-size: 20px auto;
    top: 50%;
    left: 0;
    font-size: 0;
    color: transparent;
    width: 40px;
    height: 40px;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 button.previous-story[disabled], body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 button.next-story[disabled] {
    background: #CFCEC8 url(../img/arrow-left.svg) no-repeat center center;
    background-size: 20px auto;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 button.next-story {
    background: #B01F24 url(../img/arrow-right.svg) no-repeat center center;
    background-size: 20px auto;
    left: auto;
    right: 0;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 button.next-story[disabled] {
    background: #CFCEC8 url(../img/arrow-right.svg) no-repeat center center;
    background-size: 20px auto;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 h5 {
  border-bottom: 1px solid #34576F;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 h5 {
    margin-top: 15px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories {
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    max-width: 90%;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    transition: all 1s ease-in-out;
    padding: 10px;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row.translate-left {
    transform: translate(-50%, 0);
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row.single-card {
    justify-content: center;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card {
  display: flex;
  flex-direction: row;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-top: 6px solid #B01F24;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card {
    min-width: calc(50% - 20px);
    max-width: calc(50% - 20px);
    width: calc(50% - 20px);
    float: left;
  }
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card:nth-child(2) {
    margin: 0 40px;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card * {
  opacity: 1;
  transform: translate(0);
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card .img-wrapper {
  margin-right: 15px;
  max-width: 100px;
  max-height: 100px;
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card .img-wrapper img {
  opacity: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card .img-wrapper {
    max-width: 120px;
    max-height: 120px;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card .card-content .eyebrow {
  color: #34576F !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4285714286;
  text-transform: uppercase;
  margin-top: 0;
  margin: 0 0 15px;
  font-size: 12px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card .card-content .eyebrow {
    font-size: 16px;
    line-height: 1.625;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card .card-content p {
  margin: 0 0 15px;
  font-size: 14px;
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories .card-row .card .card-content a {
  font-size: 12px;
  margin-bottom: 0;
  padding: 5px 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories.without-arrows {
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu .columns .column-3 .menu-featured-stories.without-arrows .card-row .card:nth-child(2) {
    margin: 0 0 0 40px;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu.hide {
  transform: translate(100%, 0);
  transition: all 0.65s ease-out;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu.hide {
    transform: translate(-50%, -40px);
    z-index: -1;
    opacity: 0;
  }
}
body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu.show-menu {
  transform: translate(0) !important;
  transition: all 0.8s ease;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .nav-container div[id*=layer].sub-menu.show-menu {
    opacity: 1;
    z-index: 99;
    transform: translate(-50%, 0) !important;
  }
}
body[class*=elementor-kit] section.mega_nav_container {
  overflow: hidden;
}
body[class*=elementor-kit] section.mega_nav_container button.close-menu {
  font-size: 0;
  color: transparent;
  background: #EAE9E1 url(../img/icon-close.png) no-repeat center center;
  background-size: 13px auto;
  border: 1px solid #EAE9E1;
  display: none;
  opacity: 0;
  transition: all 1s ease;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] section.mega_nav_container.menu_open {
    overflow: visible;
  }
  body[class*=elementor-kit] section.mega_nav_container.menu_open button.close-menu {
    display: block;
    opacity: 1;
    position: fixed;
    top: 160px;
    z-index: 101;
    right: 0;
    width: 40px;
    height: 40px;
  }
  body[class*=elementor-kit] section.mega_nav_container.menu_open button.close-menu:hover {
    background: #FFFFFF url(../img/icon-close.png) no-repeat center center;
    background-size: 13px auto;
    border: 1px solid #EAE9E1;
  }
}
body[class*=elementor-kit].logged-in .nav-container {
  top: 46px;
}

body[class*=elementor-kit] #alert-banner .elementor-alert {
  background: #B01F24 url(../img/icon-alert.svg) no-repeat left center;
  background-size: 26px;
  background-color: #B01F24;
  border-color: #B01F24;
  color: #fff;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4375;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] #alert-banner .elementor-alert {
    font-size: 18px;
    line-height: 1.2777777778;
  }
}
body[class*=elementor-kit] #alert-banner .elementor-alert .elementor-alert-title {
  font-weight: 600;
}
@media (max-width: 768px) {
  body[class*=elementor-kit] #alert-banner .elementor-alert .elementor-alert-title {
    max-width: 80%;
    margin: 0 auto;
  }
}
body[class*=elementor-kit] #alert-banner .elementor-alert button.elementor-alert-dismiss {
  background-color: transparent !important;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  font-size: 28px;
}

body[class*=elementor-kit] .cmplz-cookiebanner {
  grid-gap: 0px;
  width: calc(100% - 20px);
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-message {
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-message {
    margin-bottom: 0;
    max-width: 400px;
  }
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-message a {
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.35s ease;
  display: inline-block;
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-message a:hover {
  color: #F0F0EA;
  transform: translate(10px, 0);
}
@media (max-width: 1024px) {
  body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-buttons {
    gap: 0;
  }
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-buttons .cmplz-btn {
  background: #B01F24 !important;
  border-color: #B01F24 !important;
  color: #FFFFFF !important;
  border-radius: 0 !important;
  font-size: 13px;
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-buttons .cmplz-btn:hover {
  color: #B01F24 !important;
  border-color: #B01F24 !important;
  background: #FFFFFF !important;
}
@media (max-width: 768px) {
  body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-buttons .cmplz-btn {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-buttons .cmplz-btn {
    margin-left: 10px;
  }
  body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-buttons .cmplz-btn:nth-child(1) {
    margin-left: 0;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-buttons .cmplz-btn {
    min-width: 50px;
    width: auto;
  }
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-close {
  font-size: 15px;
  line-height: 20px;
  width: 15px;
  height: 15px;
  text-align: right;
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-close svg {
  width: 15px;
  height: 15px;
  transform: translate(13px, -8px);
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-close:hover {
  font-size: 15px;
  line-height: 20px;
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-categories .cmplz-category .cmplz-banner-checkbox .cmplz-label:before,
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-categories .cmplz-category .cmplz-banner-checkbox .cmplz-label:after {
  top: 0;
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-categories .cmplz-category .cmplz-banner-checkbox input.cmplz-consent-checkbox:checked + .cmplz-label::before {
  background-color: rgb(255, 255, 255);
}
body[class*=elementor-kit] .cmplz-cookiebanner .cmplz-categories .cmplz-category .cmplz-banner-checkbox .cmplz-label:after {
  background: #B01F24 !important;
  height: 15px;
  width: 15px;
}
body[class*=elementor-kit] #cmplz-manage-consent .cmplz-manage-consent {
  background-color: #34576F !important;
  border-color: #34576F !important;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] #cmplz-manage-consent .cmplz-manage-consent:hover {
    background-color: #FFFFFF !important;
    color: #34576F !important;
  }
}

body[class*=elementor-kit] .cvb-card {
  border-top: 6px solid #B01F24;
  background: #EAE9E1;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-card {
    padding: 20px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-card {
    padding: 30px;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] .cvb-card {
    padding: 40px;
  }
}
body[class*=elementor-kit] .cvb-card .card-content {
  margin-top: 0;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-card .card-content {
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-card .card-content {
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-video {
    margin-top: 120px;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] .cvb-video {
    margin-top: 150px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  body[class*=elementor-kit] .cvb-video > .elementor-container > .elementor-column:first-child {
    padding: 0 10px;
  }
}
body[class*=elementor-kit] .cvb-video > .elementor-container > .elementor-column > .elementor-widget-wrap {
  padding: 0;
}
body[class*=elementor-kit] .cvb-video .elementor-widget-video > .elementor-widget-container {
  overflow: visible;
}
@media (min-width: 768px) and (max-width: 1024px) {
  body[class*=elementor-kit] .cvb-video .elementor-widget-video > .elementor-widget-container {
    margin-left: -10px;
  }
}
body[class*=elementor-kit] .cvb-video .elementor-widget-video > .elementor-widget-container .elementor-custom-embed-image-overlay {
  position: relative;
  padding-bottom: 63%;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-video .elementor-widget-video > .elementor-widget-container .elementor-custom-embed-image-overlay {
    padding-bottom: 318px;
    padding-bottom: 0;
  }
}
body[class*=elementor-kit] .cvb-video .elementor-widget-video > .elementor-widget-container .elementor-custom-embed-image-overlay > img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  min-width: 100vw;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-video .elementor-widget-video > .elementor-widget-container .elementor-custom-embed-image-overlay > img {
    width: 57.75vw;
    min-width: 500px;
    max-width: 550px;
    left: 0;
    transform: translate(0);
    position: relative;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-video .elementor-widget-video > .elementor-widget-container .elementor-custom-embed-image-overlay > img {
    min-width: 700px;
    max-width: 800px;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] .cvb-video .elementor-widget-video > .elementor-widget-container .elementor-custom-embed-image-overlay > img {
    min-width: 800px;
    max-width: 900px;
  }
}
body[class*=elementor-kit] .cvb-video .cvb-card {
  margin-top: -30px;
  padding: 30px 25px;
}
body[class*=elementor-kit] .cvb-video .cvb-card a,
body[class*=elementor-kit] .cvb-video .cvb-card .btn,
body[class*=elementor-kit] .cvb-video .cvb-card .btn-secondary {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-video .cvb-card {
    margin-top: -90px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-video .cvb-card {
    padding: 50px;
  }
}
@media (min-width: 1500px) {
  body[class*=elementor-kit] .cvb-video .cvb-card {
    padding: 90px;
    margin-top: -120px;
  }
}

body[class*=elementor-kit] .elementor-accordion-item {
  margin-bottom: 15px;
  border: 1px solid #CFCEC8;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-accordion-item {
    margin-bottom: 40px;
  }
}
body[class*=elementor-kit] .elementor-accordion-item:last-child {
  margin-bottom: 0;
}
body[class*=elementor-kit] .elementor-accordion-item + .elementor-accordion-item {
  border-top: 1px solid #CFCEC8;
}
body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-title {
  padding: 40px 15px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-title {
    padding: 40px;
  }
}
body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-title a {
  color: #34576F;
}
body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-title a.elementor-accordion-title {
  text-decoration: none;
}
body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-title .elementor-accordion-icon {
  color: #34576F;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-title:hover .elementor-accordion-icon {
    color: #B01F24;
  }
}
body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-title.elementor-active a,
body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-title.elementor-active .elementor-accordion-icon {
  color: #B01F24;
}
body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-content {
  border-top: 1px solid #CFCEC8;
  padding: 40px 15px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-content {
    padding: 40px;
  }
}
body[class*=elementor-kit] .elementor-accordion-item .elementor-tab-content.elementor-active {
  border-top: 1px solid #B01F24;
}
body[class*=elementor-kit] section.background-cream .elementor-accordion-item {
  background: #fff;
  border: none !important;
}

body[class*=elementor-kit] .elementor-tabs-wrapper {
  overflow: visible;
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #34576F;
  font-weight: 500;
  line-height: 25px;
  position: relative;
  padding: 40px 15px;
  border-width: 2px;
  border-color: transparent;
  border-top: none;
  border-right: none;
  border-bottom: none;
  transition: all 0.65s ease;
  background: #CFCEC8;
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title:before {
  content: "";
  width: calc(100% + 2px);
  transform: translate(-2px, 0);
  height: 20px;
  background: #CFCEC8;
  top: 0;
  left: 0;
  right: auto;
  position: absolute;
  border: none;
  border-width: 2px;
  border-left: 2px solid transparent;
  transition: all 0.65s ease-out;
  z-index: -1;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title {
    padding: 40px;
  }
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+2) {
  background: #D5D4CF;
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+2):before {
  background: #D5D4CF;
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+3) {
  background: #DCDBD0;
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+3):before {
  background: #DCDBD0;
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+4) {
  background: #E1E0D8;
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title:nth-child(4n+4):before {
  background: #E1E0D8;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title:hover:before {
    transform: translate(-2px, -100%);
    bottom: auto;
    height: 20px;
    width: calc(100% + 2px);
    border: none;
    border-width: 2px;
    border-left: 2px solid transparent;
  }
}
body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title.elementor-active {
  background: #FFFFFF;
  border-left: 2px solid #B01F24;
  color: #B01F24;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-tabs-wrapper .elementor-tab-title.elementor-active:before {
    transform: translate(-2px, -100%);
    bottom: auto;
    height: 20px;
    background: #FFFFFF !important;
    width: calc(100% + 2px);
    border-bottom-style: solid;
    border: none;
    border-left: 2px solid #B01F24;
  }
}
body[class*=elementor-kit] .elementor-tabs-content-wrapper {
  border: none;
}
body[class*=elementor-kit] .elementor-tabs-content-wrapper .elementor-tab-content {
  background: #FFFFFF;
  border: 1px solid #CFCEC8;
  padding: 40px 15px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-tabs-content-wrapper .elementor-tab-content {
    padding: 40px;
  }
}
body[class*=elementor-kit] .elementor-tabs-content-wrapper .elementor-tab-content.elementor-active {
  margin-bottom: 20px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-tabs-content-wrapper .elementor-tab-content.elementor-active {
    margin-bottom: 0;
  }
}
body[class*=elementor-kit] .elementor-tab-mobile-title {
  font-size: 16px;
  color: #34576F;
  font-weight: 500;
  line-height: 20px;
  background: #FFFFFF;
  margin-bottom: 20px;
  border: 1px solid #CFCEC8;
  padding: 40px 15px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-tab-mobile-title {
    padding: 40px;
  }
}
body[class*=elementor-kit] .elementor-tab-mobile-title:before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #34576F;
  display: inline-block;
  float: right;
}
body[class*=elementor-kit] .elementor-tab-mobile-title.elementor-active {
  border-bottom: 1px solid #B01F24;
  color: #B01F24;
  margin-bottom: 0;
}
body[class*=elementor-kit] .elementor-tab-mobile-title.elementor-active:before {
  content: "\f068";
  color: #B01F24;
}
body[class*=elementor-kit] .elementor-tab-mobile-title:not(.elementor-active) {
  margin-bottom: 20px;
}
body[class*=elementor-kit] section.background-cream .elementor-tabs-wrapper .elementor-tab-title.elementor-active {
  border: none;
  border-left: 2px solid #B01F24;
}
body[class*=elementor-kit] section.background-cream .elementor-tabs-content-wrapper {
  border: none;
}
body[class*=elementor-kit] section.background-cream .elementor-tabs-content-wrapper .elementor-tab-content {
  border: none;
}
body[class*=elementor-kit] section.background-cream .elementor-tab-mobile-title {
  border: none;
}
body[class*=elementor-kit] section.background-cream .elementor-tab-mobile-title.elementor-active {
  border: none;
  border-bottom: 1px solid #B01F24;
}

body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote {
  position: relative;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote img {
  opacity: 0;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .bg-img {
  background: #34576F;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 75vw;
  transform: translate(-50%, 0);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .bg-img {
    height: 100%;
    border-bottom: none;
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .headline {
  position: absolute;
  top: 0;
  right: 0;
  background: #FFFFFF;
  width: 73%;
  padding: 20px;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .headline:after {
  content: "";
  position: absolute;
  top: 0;
  right: 1px;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  transform: translate(100%, 0);
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .headline {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .headline {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .headline:before {
  content: "";
  background: #B01F24;
  width: 50%;
  height: 2px;
  display: block;
  margin-bottom: 30px;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .headline h2 {
  margin-bottom: 0;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .quote {
  background: #FFFFFF;
  border-top: 6px solid #B01F24;
  padding: 20px 0;
  position: relative;
  z-index: 1;
  margin-top: 12vw;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .quote:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 1px;
  width: 200%;
  height: calc(100% + 6px);
  background: #FFFFFF;
  transform: translate(-25%, 0);
  border-top: 6px solid #B01F24;
  z-index: -1;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .quote {
    width: 50%;
    position: absolute;
    bottom: -1px;
    left: 0;
    padding: 20px;
    padding-left: 0;
    margin: 0;
  }
  body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .quote:after {
    width: 100%;
    transform: translate(-100%, 0);
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .quote {
    padding: 40px;
    padding-left: 0;
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .quote p:first-child:before {
  content: '"';
  display: inline-block;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .quote p:last-child:after {
  content: '"';
  display: inline-block;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote .quote .quote-author {
  color: #34576F;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote.has-cream-background .headline,
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote.has-cream-background .quote {
  background: #EAE9E1;
}
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote.has-cream-background .headline:after,
body[class*=elementor-kit] .cvb-fullwidth-callout-with-quote.has-cream-background .quote:after {
  background: #EAE9E1;
}

body[class*=elementor-kit] .cvb-fullwidth-callout {
  position: relative;
  margin-top: 60px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout {
    margin-top: 90px;
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout img {
  opacity: 0;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout img {
    margin-top: 90px;
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout .bg-img {
  background: #34576F;
  position: absolute;
  top: 0;
  right: 15px;
  width: calc(100vw - 15px);
  height: 400px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout .bg-img {
    top: 40px;
    height: 450px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout .bg-img {
    height: 100%;
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout .copy {
  background: #FFFFFF;
  width: 82%;
  margin-left: 18%;
  padding: 20px;
  border-top: 6px solid #B01F24;
  margin-top: -30px;
  position: relative;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout .copy {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout .copy {
    margin-top: -80px;
    padding: 80px 0 80px 40px;
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout .copy a:last-child {
  margin-bottom: 0;
}
body[class*=elementor-kit] .cvb-fullwidth-callout .copy:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 1px;
  width: 100%;
  height: calc(100% + 6px);
  background: #FFFFFF;
  transform: translate(100%, 0);
  border-top: 6px solid #B01F24;
}
body[class*=elementor-kit] .cvb-fullwidth-callout .copy .link-button {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, -6px);
  background-color: #B01F24 !important;
  color: #FFFFFF;
  padding: 15px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout .copy .link-button:hover {
    background: #FFFFFF !important;
    color: #B01F24 !important;
  }
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout .copy .link-button {
    bottom: 0;
    top: auto;
    transform: translate(-100%, 0);
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout .copy .callout-content {
  margin-top: 0;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout .copy .callout-content {
    margin-bottom: 20px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-fullwidth-callout .copy .callout-content {
    margin-bottom: 25px;
  }
}
body[class*=elementor-kit] .cvb-fullwidth-callout.has-cream-background .copy {
  background: #EAE9E1;
}
body[class*=elementor-kit] .cvb-fullwidth-callout.has-cream-background .copy:after {
  background: #EAE9E1;
}

body[class*=elementor-kit] #breadcrumbs {
  padding: 0;
  /*padding: 0 ($gutter * 0.5);
  @media (min-width: $tablet) {
   padding: 0 ($gutter-tablet * 0.5);
  }
  @media (min-width: $laptop) {
   padding: 0 ($gutter-laptop * 0.5);
  }
  @media (min-width: $desktop) {
   padding: 0 ($gutter-desktop * 0.5);
  }*/
}
body[class*=elementor-kit] #breadcrumbs *,
body[class*=elementor-kit] #breadcrumbs a {
  font-family: semplicitapro, sans-serif;
  line-height: 2;
  font-size: 13px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] #breadcrumbs *,
body[class*=elementor-kit] #breadcrumbs a {
    font-size: 16px;
    line-height: 1.3333333333;
  }
}
body[class*=elementor-kit] #breadcrumbs a {
  text-decoration: none;
}
body[class*=elementor-kit] #breadcrumbs a:hover {
  text-decoration: underline;
}

body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-container {
  height: 350px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-container {
    height: 400px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-container {
    height: 500px;
  }
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-icon-image,
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-icon-image-back {
  position: absolute !important;
  top: 20px !important;
  left: 20px !important;
  color: #34576F !important;
  font-family: semplicitapro, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4285714286;
  text-transform: uppercase;
  margin-top: 0;
  color: #FFFFFF !important;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-icon-image,
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-icon-image-back {
    font-size: 16px;
    line-height: 1.625;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-icon-image,
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-icon-image-back {
    top: 40px !important;
    left: 40px !important;
  }
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-overlay {
  padding: 20px;
  text-align: left !important;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-overlay {
    padding: 40px;
  }
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-front,
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-back {
  border-top: 6px solid #B01F24;
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-front {
  background-color: rgb(97, 136, 173);
  background-blend-mode: multiply;
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-front .pp-flipbox-overlay {
  background-color: rgba(97, 136, 173, 0) !important;
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-front .pp-flipbox-heading {
  margin: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-front .pp-flipbox-heading {
    margin: 34px 0;
  }
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-back {
  background: #EAE9E1;
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-back .pp-flipbox-icon-image-back {
  color: #34576F !important;
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-back .pp-flipbox-content {
  color: #66717D;
  margin-bottom: 20px;
}
body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-back .pp-flipbox-button {
  color: #B01F24;
  background: transparent;
  border: none;
  border-top: 1px solid #B01F24;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-widget-pp-flipbox .pp-flipbox-back .pp-flipbox-button:hover {
    border-top: 1px solid #34576F;
    color: #34576F;
  }
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .elementor-col-50 .elementor-widget-pp-flipbox {
    width: 100%;
  }
}
body[class*=elementor-kit] .pp-post {
  overflow: visible;
}
body[class*=elementor-kit] .card-eyebrow .elementor-icon-list-icon {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}
body[class*=elementor-kit] .cvb-related-content-card {
  background: #FFFFFF;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 100%;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-related-content-card {
    padding: 20px;
  }
  body[class*=elementor-kit] .cvb-related-content-card > .elementor-container > .elementor-col-100 > .elementor-widget-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-related-content-card {
    padding: 30px;
  }
}
body[class*=elementor-kit] .cvb-related-content-card .card-eyebrow .elementor-icon-list-icon + .elementor-icon-list-text {
  padding-left: 0;
}
body[class*=elementor-kit] .cvb-related-content-card .elementor-widget-theme-post-featured-image {
  opacity: 0;
  margin-bottom: 20px;
}
body[class*=elementor-kit] .cvb-related-content-card-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
body[class*=elementor-kit] .cvb-related-content-card-content {
  text-align: left;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .elementor-grid-1 .cvb-related-content-card .cvb-related-content-card-img,
body[class*=elementor-kit] .elementor-grid-1 .cvb-related-content-card .cvb-related-content-card-content {
    width: 50%;
  }
  body[class*=elementor-kit] .elementor-grid-1 .cvb-related-content-card .cvb-related-content-card-content {
    padding-right: 10px;
  }
  body[class*=elementor-kit] .elementor-grid-1 .cvb-related-content-card .cvb-related-content-card-img {
    float: right;
    margin-left: 10px;
    width: calc(50% - 10px);
  }
  body[class*=elementor-kit] .elementor-grid-1 .cvb-related-content-card > .elementor-container > .elementor-element > .elementor-widget-wrap {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .elementor-grid-1 .cvb-related-content-card .cvb-related-content-card-content {
    padding-right: 20px;
  }
  body[class*=elementor-kit] .elementor-grid-1 .cvb-related-content-card .cvb-related-content-card-img {
    float: right;
    margin-left: 20px;
    width: calc(50% - 20px);
  }
}
body[class*=elementor-kit] .pp-posts-container.swiper-container-wrap {
  display: none !important;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .pp-posts-container.swiper-container-wrap {
    margin-top: 100px;
  }
}
body[class*=elementor-kit] .pp-posts-container.swiper-container-wrap .pp-posts {
  overflow: visible;
}
body[class*=elementor-kit] .pp-posts-container.swiper-container-wrap .cvb-related-content-card {
  margin: 0;
}
body[class*=elementor-kit] .pp-equal-height-yes .pp-posts-container .pp-grid-item > .elementor-template {
  height: 100%;
}
body[class*=elementor-kit] .pp-equal-height-yes .pp-posts-container .pp-grid-item > .elementor-template [data-elementor-type=loop] {
  height: 100%;
}

/* carousel */
.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.cvb-posts-carousel-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-stage {
  margin-left: -15px;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .item {
  opacity: 0;
  transform: scale3d(0.87, 0.87, 1) translate(-50%, 0);
  transition: all 0.3s ease-in-out;
  min-width: 200px;
  position: relative;
  left: 50%;
  padding: 20px;
  background: transparent;
  border-top: 6px solid transparent;
}
@media (min-width: 768px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel .item {
    min-width: 300px;
  }
}
@media (min-width: 1024px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel .item {
    min-width: 375px;
  }
}
@media (min-width: 1500px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel .item {
    min-width: 420px;
  }
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .item .item-img {
  background-color: #CFCEC8;
  padding-bottom: 63%;
  width: 100%;
  margin-bottom: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .item .item-content {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .item .item-content .excerpt {
  height: 120px;
  margin-bottom: 15px;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item.active {
  position: relative;
  z-index: 1;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item.active .item {
  opacity: 0.25;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item.active.center {
  z-index: 2;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item.active.center .item {
  opacity: 1;
  min-width: 280px;
  background: #FFFFFF;
  border-top: 6px solid #B01F24;
  transform: scale3d(1, 1, 1) translate(calc(-50% + 15px), 0);
}
@media (min-width: 768px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item.active.center .item {
    min-width: 367px;
  }
}
@media (min-width: 1024px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item.active.center .item {
    min-width: 428px;
  }
}
@media (min-width: 1500px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item.active.center .item {
    min-width: 450px;
  }
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item.active.center .item .item-content {
  opacity: 1;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel .owl-item img {
  transform-style: preserve-3d;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 40px;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-dots .owl-dot span {
  background: #D1CDCD;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.7);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 20px;
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-dots .owl-dot.active span {
  background: url(../img/nav-dot-active.svg) no-repeat center center;
  background-size: cover;
  transform: translate3d(0px, -50%, 0px) scale(1);
}
@media (min-width: 1024px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-dots .owl-dot:hover span {
    transform: translate3d(0px, -50%, 0px) scale(1);
  }
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-nav {
  position: absolute;
  top: 31%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 280px;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-nav {
    width: 367px;
  }
}
@media (min-width: 1024px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-nav {
    width: 428px;
  }
}
@media (min-width: 1500px) {
  .cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-nav {
    width: 450px;
  }
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-nav .owl-prev,
.cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-nav .owl-next {
  color: #FFFFFF;
  display: inline-block;
  padding: 10px;
  width: 35px;
  height: 35px;
  font-size: 0;
  background: #B01F24 url(../img/arrow-right.svg) no-repeat center center;
  background-size: 20px auto;
  transform: translate(100%, 0);
}
.cvb-posts-carousel-wrapper .cvb-posts-carousel.owl-carousel .owl-nav .owl-prev {
  transform: translate(-100%, 0);
  background: #B01F24 url(../img/arrow-left.svg) no-repeat center center;
  background-size: 20px auto;
}

body[class*=elementor-kit] .cvb-stats {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 650px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-stats {
    min-height: 500px;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-stats {
    min-height: 750px;
  }
}
body[class*=elementor-kit] .cvb-stats .stat_card {
  width: 100%;
  height: 100%;
  position: absolute;
}
body[class*=elementor-kit] .cvb-stats .stat_card .half {
  width: 100%;
  transform: translate(0, 0);
  transition: all 350ms ease-in-out;
  background: #FFFFFF;
  padding: 15px;
  padding-bottom: 40px;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-stats .stat_card .half {
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-stats .stat_card .half {
    padding: 40px;
  }
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right {
  height: 400px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-stats .stat_card .half.right {
    height: 100%;
    left: 50%;
  }
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right .pagination {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7.5px 40px 0 0;
  border-top: 1px solid #B01F24;
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right img,
body[class*=elementor-kit] .cvb-stats .stat_card .half.right .card-content {
  margin-bottom: 40px;
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right img {
  max-height: 54px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-stats .stat_card .half.right img {
    max-height: 145px;
  }
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right .h1 {
  color: #34576F;
  margin-bottom: 7.5px;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-stats .stat_card .half.right .h1 {
    margin-bottom: 20px;
  }
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right .footnote {
  color: #34576F;
  font-size: 12px;
  position: relative;
  padding: 7.5px 0;
  background: url(../img/footnote-accent.png) no-repeat top left;
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right .footnote a {
  color: #34576F;
  font-size: 12px;
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right .navigation {
  position: absolute;
  bottom: 0;
  right: 0;
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right .navigation button.prev, body[class*=elementor-kit] .cvb-stats .stat_card .half.right .navigation button.next {
  border: none;
  color: #FFFFFF;
  display: inline-block;
  padding: 0;
  width: 35px;
  height: 35px;
  font-size: 0;
  background: #B01F24 url(../img/arrow-right.svg) no-repeat center center;
  background-size: 20px auto;
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  body[class*=elementor-kit] .cvb-stats .stat_card .half.right .navigation button.prev, body[class*=elementor-kit] .cvb-stats .stat_card .half.right .navigation button.next {
    width: 60px;
    height: 60px;
  }
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.right .navigation button.prev {
  background: #EAE9E1 url(../img/arrow-left-red.svg) no-repeat center center;
  background-size: 20px auto;
}
body[class*=elementor-kit] .cvb-stats .stat_card .half.left {
  padding: 0;
  height: 250px;
}
@media (min-width: 768px) {
  body[class*=elementor-kit] .cvb-stats .stat_card .half.left {
    height: 100%;
  }
}
body[class*=elementor-kit] .cvb-stats .stat_card.hidden {
  display: none;
}
body[class*=elementor-kit] .cvb-stats .stat_card.open .right {
  transform: translate(100%, 0);
}
body[class*=elementor-kit] .cvb-stats .stat_card.open .left {
  transform: translate(-100%, 0);
}