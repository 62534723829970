.background-cream {
	background: $cream;
}
.force-fullwidth-background-cream {
	background: $cream;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background: $cream;
		width: 100vw;
		height: 100%;
		top: 0;
		left: 50%;
		transform: translate(-50%,0);
	}
}