body[class*="elementor-kit"] {
	.cvb-fullwidth-callout-with-quote {
		position: relative;
		img {
			opacity: 0;
		}
		.bg-img {
			background: $darkblue;
			position: absolute;
			top: 0;
			left: 50%;
			width: 100vw;
			height: 75vw;
			transform: translate(-50%,0);
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			@media (min-width: $tablet) {
				height: 100%;
				border-bottom: none;
			}
		}
		.headline {
			position: absolute;
			top: 0;
			right: 0;
			background: $white;
			width: 73%;
			padding: $container-spacing-tablet;
			&:after {
				content: "";
				position: absolute;
				top: 0;
				right: 1px;
				width: 100%;
				height: 100%;
				background: $white;
				transform: translate(100%,0);
			}
			@media (min-width: $tablet) {
				width: 50%;
			}
			@media (min-width: $laptop) {
				padding: $container-spacing-desktop;
			    display: flex;
			    flex-direction: column;
			    justify-content: flex-end;
			}
			&:before {
				content: "";
				background: $red;
				width: 50%;
				height: 2px;
				display: block;
				margin-bottom: $container-spacing-mobile * 2;
			}
			h2 {
				margin-bottom: 0;
			}
		}
		.quote {
			background: $white;
			border-top: 6px solid $red;
			padding: $container-spacing-tablet 0;
			position: relative;
			z-index: 1;
			margin-top: 12vw;
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 1px;
				width: 200%;
				height: calc(100% + 6px);
				background: $white;
				transform: translate(-25%,0);
				border-top: 6px solid $red;
				z-index: -1;
			}
			@media (min-width: $tablet) {
				width: 50%;
				position: absolute;
				bottom: -1px;
				left: 0;
				padding: $container-spacing-tablet;
				padding-left: 0;
				margin: 0;
				&:after {
					width: 100%;
					transform: translate(-100%,0);
				}
			}
			@media (min-width: $laptop) {
				padding: $container-spacing-desktop;
				padding-left:0;
			}
			p {
				&:first-child {
					&:before {
						content: '"';
						display: inline-block;
					}
				}
				&:last-child {
					&:after {
						content: '"';
						display: inline-block;
					}
				}
			}
			.quote-author {
				color: $darkblue;
			}
		}
		&.has-cream-background {
			.headline,
			.quote {
				background: $cream;
				&:after {
					background: $cream;
				}
			}
		}
	}
}