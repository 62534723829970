body[class*="elementor-kit"] {
	.elementor-tabs-wrapper {
		overflow:visible;
		.elementor-tab-title {
			font-size: 18px;
			text-transform: uppercase;
			color: $darkblue;
			font-weight: 500;
			line-height: 25px;
			position: relative;
			padding: $container-spacing-desktop $container-spacing-mobile;
			border-width:2px;
			border-color: transparent;
			border-top:none;
			border-right:none;
			border-bottom:none;
			transition: all .65s ease;
			background: $tangray;
			&:before {
				content:"";
				width: calc(100% + 2px);
				transform: translate(-2px,0);	
				height:20px;
				background:$tangray;
				top:0;
				left:0;
				right:auto;
				position:absolute;
			    border: none;
				border-width:2px;
			    border-left:2px solid transparent;
				transition: all .65s ease-out;
				z-index:-1;
			}
			@media (min-width: $laptop) {
				padding: $container-spacing-desktop;
			}
			&:nth-child(4n+2) { //every fourth child starting at the second
				background: $lighttan;
				&:before {
					background:$lighttan;
				}
			}
			&:nth-child(4n+3) {
				background: $darkbeige;
				&:before {
					background:$darkbeige;
				}
			}
			&:nth-child(4n+4) {
				background: $beige;
				&:before {
					background:$beige;
				}
			}
			&:hover {
				&:before {
					@media (min-width:$laptop) {
						transform: translate(-2px,-100%);	
						bottom: auto;
						height: 20px;
						width: calc(100% + 2px);
						border:none;
						border-width:2px;
						border-left:2px solid transparent;
					}
				}
			}
			&.elementor-active {
				background: $white;
				border-left: 2px solid $red;
				color: $red;
				&:before {
					@media (min-width:$laptop) {
    					transform: translate(-2px,-100%);	
						bottom: auto;
						height: 20px;
						background:$white !important;
						width: calc(100% + 2px);
						border-bottom-style: solid;
						border:none;
					    border-left:2px solid $red;
					}
				}
			}
		}
	}
	.elementor-tabs-content-wrapper {
		border: none;
		.elementor-tab-content {
			background: $white;
			border: 1px solid $tangray;
			padding: $container-spacing-desktop $container-spacing-mobile;
			@media (min-width: $laptop) {
    			padding: $container-spacing-desktop;
    		}
			&.elementor-active {
				margin-bottom: 20px;
				@media (min-width: $laptop) {
					margin-bottom: 0;
				}
			}
		}
	}
	.elementor-tab-mobile-title {
		font-size: 16px;
		color: $darkblue;
		font-weight: 500;
		line-height: 20px;
		background: $white;
		margin-bottom: 20px;
		border: 1px solid $tangray;
		padding: $container-spacing-desktop $container-spacing-mobile;
		@media (min-width: $laptop) {
			padding: $container-spacing-desktop;
		}
		&:before {
		   content: "\f067";
		   font-family: "Font Awesome 5 Free";
    		font-weight: 900;
    		-webkit-font-smoothing: antialiased;
		    font-style: normal;
		    font-variant: normal;
		    text-rendering: auto;
		    line-height: 1;
		    color: $darkblue;
		    display: inline-block;
		    float: right;
		}
		&.elementor-active {
			border-bottom: 1px solid $red;
			color: $red;
			margin-bottom: 0;
			&:before {
			    content: "\f068";
			    color: $red;
			}
		}
		&:not(.elementor-active) {
			margin-bottom: 20px;
		}
	}
	section {
		&.background-cream {
			.elementor-tabs-wrapper {
				.elementor-tab-title {
					&.elementor-active {
						border: none;
						border-left: 2px solid $red;
					}
				}
			}
			.elementor-tabs-content-wrapper {
				border: none;
				.elementor-tab-content {
					border: none;
				}
			}
			.elementor-tab-mobile-title {
				border: none;
				&.elementor-active {
					border: none;
					border-bottom: 1px solid $red;
				}
			}
		}
	}
}