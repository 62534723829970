body[class*="elementor-kit"] {
	@include body-copy();
	h1,
	.h1 {
		@include h1();
		&.elementor-widget-heading,
		&.elementor-widget-heading {
			@include h1();		
		}
	}

	h2,
	.h2 {
		@include h2();
		&.elementor-widget-heading,
		&.elementor-widget-heading {
			@include h2();		
		}
	}

	h3,
	.h3 {
		@include h3();
		&.elementor-widget-heading,
		&.elementor-widget-heading {
			@include h3();		
		}
	}

	h4,
	.h4 {
		@include h4();
		&.elementor-widget-heading,
		&.elementor-widget-heading {
			@include h4();		
		}
	}

	h5,
	.h5 {
		@include h5();
		&.elementor-widget-heading,
		&.elementor-widget-heading {
			@include h5();		
		}
	}

	button, .btn, p {
		@include text-spacing();
	}

	a,
	.elementor p a {
		@include text-link();
	}

	a.btn,
	button,
	.btn,
	.elementor-button-link,
	.elementor-button {
		@include button();
		&.btn-secondary {
			@include button-style-secondary();
		}
	}

	.btn-secondary {
		a,
		button,
		.btn,
		.elementor-button-link,
		.elementor-button {
			@include button-style-secondary();
		}
	}

	p:last-child {
		margin-bottom: 0;
	}
	
	.text-uppercase {
		text-transform: uppercase;
	}

	.text-center {
		text-align: center;
	}

	.text-no-wrap {
		white-space: nowrap;
	}

	.small-text {
		@include small-text();
	}
}