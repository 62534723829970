body[class*="elementor-kit"] {
	#header-icon {
		position: relative;
		&:before,
		&:after {
			content: "";
			background: $red;
			position: absolute;
			top: 0;
			right: 0;
			width: 120px;
			height: 5px;
			transform: translate(3px,-5px);
		}
		&:after {
			width: 5px;
			height: 120px;
		}
		img {
			width: 100%;
		}
	}
}