body[class*="elementor-kit"] {
	.pp-post {
		overflow: visible;
	}
	.card-eyebrow {
		.elementor-icon-list-icon {
		    opacity: 0;
		    z-index: -1;
		    visibility: hidden;
		}
	}
	.cvb-related-content-card {
		background: $white;
		padding-top:  15px;
		padding-bottom: 15px;
		height: 100%;
		@media (min-width: $tablet) {
			padding: $container-spacing-tablet;
			& > .elementor-container {
				& > .elementor-col-100 {
					& > .elementor-widget-wrap {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
		@media (min-width: $laptop) {
			padding: $container-spacing-laptop;
		}
		.card-eyebrow {
			.elementor-icon-list-icon+.elementor-icon-list-text {
				padding-left: 0;
			}
		}
		.elementor-widget-theme-post-featured-image {
			opacity: 0;
			margin-bottom: 20px;
		}
	}
	.cvb-related-content-card-img {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}
	.cvb-related-content-card-content {
		text-align: left;
	}
	.elementor-grid-1 {
		.cvb-related-content-card {
			@media (min-width: $tablet) {
				.cvb-related-content-card-img,
				.cvb-related-content-card-content {
					width: 50%;
				}
				.cvb-related-content-card-content {
					padding-right: 10px;
				}
				.cvb-related-content-card-img {
					float: right;
					margin-left: 10px;
					width: calc(50% - 10px);
				}
				& > .elementor-container > .elementor-element > .elementor-widget-wrap {
				    flex-direction: row-reverse;
				}
			}
			@media (min-width: $laptop) {
				.cvb-related-content-card-content {
					padding-right: 20px;
				}
				.cvb-related-content-card-img {
					float: right;
					margin-left: 20px;
					width: calc(50% - 20px);
				}
			}
		}
	}
	
	.pp-posts-container.swiper-container-wrap {
		display: none !important;
		@media (min-width: $laptop) {
			margin-top: 100px;
		}
		.pp-posts {
			overflow: visible;
		}
		.cvb-related-content-card {
			margin: 0;
		}
	}
	.pp-equal-height-yes {
		.pp-posts-container {
			.pp-grid-item {
				& > .elementor-template {
					height: 100%;
					[data-elementor-type="loop"] {
						height: 100%;
					}
				}
			}
		}
	}
}

/* carousel */

.owl-carousel .owl-stage-outer {
	overflow: visible;
}

.cvb-posts-carousel-section {
	& > .elementor-container {
		.e-swiper-container {
		}
	}
}

.cvb-posts-carousel-wrapper {
	max-width: 1000px;
	margin: 0 auto;
	.cvb-posts-carousel {
		.owl-stage {
			margin-left: -15px;
		}
		.item {
			opacity: 0;
		    transform: scale3d(0.87, 0.87, 1) translate(-50%,0);
		    transition: all 0.3s ease-in-out;
		    min-width: 200px;
		    position: relative;
		    left: 50%;
		    padding: 20px;
		    background: transparent;
		    border-top: 6px solid transparent;
		    @media (min-width: $tablet) {
		    	min-width: 300px;
		    }
		    @media (min-width: $laptop) {
		    	min-width: 375px;
		    }
		    @media (min-width: $desktop) {
		    	min-width: 420px;
		    }
		    .item-img {
		    	background-color: $tangray;
		    	padding-bottom: 63%;
		    	width: 100%;
		    	margin-bottom: $container-spacing-mobile;
		    	background-size: cover;
		    	background-repeat: no-repeat;
		    	background-position: center center;
		    }
		    .item-content {
		    	opacity: 0;
			    transition: all 0.3s ease-in-out;
			    .excerpt {
			    	height: 120px;
			    	margin-bottom: $container-spacing-mobile;
			    }
		    }
		}
		.owl-item {
			&.active {
				position: relative;
				z-index: 1;
				.item {
				    opacity: 0.25;
				}
				&.center {
					z-index: 2;
					 .item {
					 	opacity: 1;
					 	min-width: 280px;
					    background: $white;
					    border-top: 6px solid $red;
					    transform: scale3d(1, 1, 1) translate(calc(-50% + 15px),0);
					    @media (min-width: $tablet) {
					    	min-width: 367px;
					    }
					    @media (min-width: $laptop) {
					    	min-width: 428px;
					    }
					    @media (min-width: $desktop) {
					    	min-width: 450px;
					    }
					    .item-content {
					    	opacity: 1;
					    }
					}
				}
			}
			img {
				transform-style: preserve-3d;
			}	
		}
		&.owl-carousel {
			.owl-dots {
				display: inline-block;
				width: 100%;
				text-align: center;
				padding-top: $container-spacing-desktop;
				.owl-dot {
					display: inline-block;
					span {
					    background: #D1CDCD;
					    border-radius: 50%;
					    display: inline-block;
					    height: 20px;
					    margin: 0 2px 5px;
					    transform: translate3d(0px, -50%, 0px) scale(0.7);
					    transform-origin: 50% 50% 0;
					    transition: all 250ms ease-out 0s;
					    width: 20px;
					}
					&.active {
						span {
							background: url(../img/nav-dot-active.svg) no-repeat center center;
							background-size: cover;
    						transform: translate3d(0px, -50%, 0px) scale(1);
						}
					}
					&:hover {
						span {
							@media(min-width: $laptop) {
    							transform: translate3d(0px, -50%, 0px) scale(1);
							}
						}
					}
				}
			}
			.owl-nav {
				position: absolute;
			    top: 31%;
			    left: 50%;
			    transform: translate(-50%,-50%);
		        display: flex;
			    width: 280px;
			    justify-content: space-between;
			    @media (min-width: $tablet) {
			    	width: 367px;
			    }
			    @media (min-width: $laptop) {
			    	width: 428px;
			    }
			    @media (min-width: $desktop) {
			    	width: 450px;
			    }
				.owl-prev,
				.owl-next {
					color: $white;
				    display: inline-block;
    				padding: 10px;
    				width: 35px;
    				height: 35px;
					font-size: 0;
					background: $red url(../img/arrow-right.svg) no-repeat center center;
					background-size: 20px auto;
					transform: translate(100%,0);
				}
				.owl-prev {
					transform: translate(-100%,0);
					background: $red url(../img/arrow-left.svg) no-repeat center center;
					background-size: 20px auto;
				}
			}
		}
	}
}
