/* ================================
** Utility
================================ */


@mixin remToPx($value) {
  font-size: calc($value*$scale) * 1px;
}

@mixin remToPxMaxWidth($value) {
  max-width: calc($value*$scale) * 1px;
}

@mixin letter-spacing($spacing) {
  letter-spacing: calc($spacing/1000) * 1em;
}

@mixin line-height($line-height,$font-size) {
  line-height: calc($line-height / $font-size);
}


/* ================================
** Typography
================================ */

@mixin h1() {
	font-family: $semplicitapro;
	color: $white;
	font-weight: 500;
	text-transform: uppercase;
	@include remToPx(3.8);
	@include line-height(45,38);
	margin-top: 0;
	@media (min-width: $tablet) {
		@include remToPx(6);
		@include line-height(65,60);
	}
	@media (min-width: $laptop) {
		@include remToPx(9);
		@include line-height(90,90);
	}
	@media (min-width: $desktop) {
		@include remToPx(10);
		@include line-height(100,100);
	}
}

@mixin h2() {
	color:$darkblue !important;
	font-weight: 400;
	font-family: $semplicitapro;
	@include remToPx(2);
	@include line-height(25,20);
	margin-top: 0;
	@media (min-width: $laptop) {
		@include remToPx(3);
		@include line-height(40,30);
	}
}

@mixin h3() {
	color:$darkblue !important;
	font-family: $semplicitapro;
	font-weight: 400;
	@include remToPx(1.6);
	@include line-height(22,16);
	margin-top: 0;
	@media (min-width: $laptop) {
		@include remToPx(2.2);
		@include line-height(34,26);
	}
}

@mixin h4() {
	color:$darkgray !important;
	font-family: $semplicitapro;
	font-weight:700;
	@include remToPx(1.6);
	@include line-height(28,16);
	@include letter-spacing(5);
	margin-top: 0;
	@media (min-width: $laptop){
		@include remToPx(2);
		@include line-height(30,20);
	}
}

@mixin h5() {
	color:$darkblue !important;
	font-family: $semplicitapro;
	font-weight:500;
	font-size: 14px;
	@include line-height(20,14);
	text-transform: uppercase;
	margin-top: 0;
	@media (min-width: $laptop) {
		@include remToPx(1.6);
		@include line-height(26,16);
	}
}

@mixin body-copy() {
	color:$darkgray !important;
	font-family: $semplicitapro;
	font-weight:500;
	@include remToPx(1.6);
	@include line-height(20,16);
	@media (min-width: $laptop) {
		@include remToPx(1.7);
		@include line-height(28,17);		
	}
}

@mixin text-link() {
	color:$red;
	font-family: $semplicitapro;
	font-weight:500;
	text-decoration: underline;
	@media (min-width: $laptop) {
		&:hover {
			color: $darkblue;
		}
	}
}

@mixin button() {
	color: $white;
	background: $red;
	border: 1px solid $red;
	font-family: $semplicitapro;
	font-weight:500;
	@include letter-spacing(50);
	@include line-height(19,14);
	border-radius:  0;
  font-size: 14px;
  padding: 10px 20px;
	text-transform: uppercase;
	-webkit-appearance: none;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	&[disabled] {
		background: $tangray;
		border-color: $tangray;
	}
	@media (min-width: $laptop) {
	  font-size: 16px;
		@include line-height(19,16);
		&:hover {
			color: $red;
			background: $white;
			&[disabled] {
				background: $tangray;
			}
		}
	}
}

@mixin button-style-secondary() {
	color: $red;
	background: transparent;
	border: none;
	border-top: 1px solid $red;
	padding-left: 0;
	padding-right: 0;
	@media (min-width: $laptop) {
		&:hover {
			border-top: 1px solid $darkblue;
			color: $darkblue;
		}
	}
}


@mixin form-field() {
	-webkit-appearance: none;
	width: 100%;
	background: #fff;
	outline-color: $darkblue;
	border: 2px solid #C6C5C5;
	border-radius: 16px;
	box-sizing: border-box;
  padding: 10px 20px;
	margin-bottom: 25px;
}

@mixin small-text() {
	font-size: 12px;
}

@mixin ellipsis($before:true) {
  content: "\2026";
  font-size: 24px;
  display: inline-block;
  @if($before) {
    margin-right: 18px;
  } @else {
    margin-left: 18px;
  }
}


/* ================================
** Spacing
================================ */


@mixin text-spacing() {
	margin-top: 0;
	margin-bottom: 16px;
	@media (min-width: $tablet) {
		margin-bottom: 20px;
	}
	@media (min-width: $laptop) {
		margin-bottom: 25px;
	}
}

@mixin text-inside-card-spacing() {
	margin: 0 0 25px;
}

@mixin card-spacing() {
	padding: $gutter;
	@media (min-width: $tablet) {
		padding: $gutter-tablet;
	}
	@media (min-width: $laptop) {
		padding: $gutter-laptop;
	}
	@media (min-width: $desktop) {
		padding: $gutter-desktop;
	}
}

@mixin input-spacing() {
	padding: 20px 40px;
}

@mixin container-padding() {
	padding-top: $container-spacing-mobile;
	padding-bottom: $container-spacing-mobile;
	@media (min-width: $tablet){
		padding-top: $container-spacing-tablet;
		padding-bottom: $container-spacing-tablet;
	}
	@media (min-width: $laptop){
		padding-top: $container-spacing-laptop;
		padding-bottom: $container-spacing-laptop;
	}
	@media (min-width: $desktop){
		padding-top: $container-spacing-desktop;
		padding-bottom: $container-spacing-desktop;
	}
}

@mixin container-margin() {
	margin-bottom: 100px;
}

/* ================================
** Layout / Structure
================================ */

/*
desktop: http://gridcalculator.dk/#/1300/12/50/0
laptop: http://gridcalculator.dk/#/1000/12/40/0
tablet: http://gridcalculator.dk/#/600/12/20/0
mobile: http://gridcalculator.dk/#/350/12/15/0
*/

@mixin content-well() {
	width: 100%;
	padding-left: $gutter;
	padding-right: $gutter;
	margin: 0 auto;
	box-sizing: border-box;
	@include remToPxMaxWidth(60);
	@media (min-width: $tablet) {
		padding: 0;
	}
	@media (min-width: $laptop) {
		@include remToPxMaxWidth(100);
	}
	@media (min-width: $desktop) {
		@include remToPxMaxWidth(120);
	}
}


@mixin card() {
	border-top: 6px solid $red;
	background: $cream;
  width: 100%;
  box-sizing: border-box;
  @include card-spacing();
}


