body[class*="elementor-kit"] {
	#alert-banner {
		.elementor-alert {
			background: $red url(../img/icon-alert.svg) no-repeat left center;
			background-size: 26px;
			background-color: $red;
			border-color: $red;
			color: #fff;
			position: relative;
			font-weight: 600;
			font-size: 16px;
			@include line-height(23,16);
			text-align: center;
			padding-top: 20px;
			padding-bottom: 20px;
			@media (min-width: $laptop) {
				font-size: 18px;
				@include line-height(23,18);
			}
			.elementor-alert-title {
				font-weight: 600;
				@media (max-width: $tablet) {
				    max-width: 80%;
	    			margin: 0 auto;
				}
			}
		}
		.elementor-alert button.elementor-alert-dismiss {
			background-color: transparent !important;
		    margin: 0;
		    position: absolute;
		    top: 50%;
		    right: 0;
		    transform: translate(0,-50%);
		    font-size: 28px;
		}
	}
}