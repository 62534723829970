body[class*="elementor-kit"] {
	.elementor-column-gap-default {
		& >.elementor-column>.elementor-element-populated {
		    padding: 0;
		    @media (min-width: $tablet) {
			    padding: 0 ($gutter-tablet * 0.5);
		    }
		    @media (min-width: $laptop) {
			    padding: 0 ($gutter-laptop * 0.5);
		    }
		    @media (min-width: $desktop) {
			    padding: 0 ($gutter-desktop * 0.5);
		    }
		}
	    & > .elementor-col-50 {
	    	margin-bottom: 15px;
	    }
	}
	section.elementor-top-section {
		@include container-margin();
		 & > .elementor-column-gap-default>.elementor-column>.elementor-element-populated {
		    padding-left: 0;
		    padding-right: 0;
		}
	}
	header .elementor-section.elementor-section-boxed > .elementor-container,
	footer .elementor-section.elementor-section-boxed > .elementor-container,
	.elementor-top-section.elementor-section.elementor-section-boxed > .elementor-container {
		@include content-well();
	}
	/* what is this? */
	section.elementor-section-stretched.elementor-top-section > .elementor-container {
		padding-left: $gutter;
		padding-right: $gutter;
		@media (min-width: $tablet) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	section {
		&.background-cream {
			padding-top: $container-spacing-mobile;
			padding-bottom: $container-spacing-mobile;
			@media (min-width: $laptop) {
				padding-top: $container-spacing-desktop;
				padding-bottom: $container-spacing-desktop;
			}
			& + .background-cream {
				margin-top: -100px;
				padding-top:100px; 
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}