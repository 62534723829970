body[class*="elementor-kit"] {

	.add-padding-right-mobile {
		@media (max-width: $tablet) {
			padding-right: $container-spacing-mobile;
		}
	}
	.hide-on-mobile {
		@media (max-width: $tablet) {
			display: none;
		}
	}

	.hide-on-tablet {
		@media (min-width: $tablet) and (max-width: $laptop) {
			display: none;
		}
	}

	.hide-on-laptop {
		@media (min-width: $laptop) and (max-width: $desktop) {
			display: none;
		}
	}

	.hide-on-desktop {
		@media (min-width: $desktop) {
			display: none;
		}
	}

	.display-block-on-mobile {
		@media (max-width: $tablet) {
			display: block;
		}
	}

	.display-block-on-tablet {
		@media (min-width: $tablet) and (max-width: $laptop) {
			display: block;
		}
	}

	.display-block-on-laptop {
		@media (min-width: $laptop) {
			display: block;
		}
	}

	.display-block-on-desktop {
		@media (min-width: $desktop) {
			display: block;
		}
	}

	.height-1 {
		height: 1px;
	}

	.indent-on-laptop {
		@media (min-width: $laptop) {
			display: inline-block;
			width: 100px;
		}
	}

	.force-fullwidth-columns-mobile {
		.elementor-column {
			@media (max-width: $tablet) {
				width: 100% !important;
				min-width: 100% !important;
			}			
		}
	}
}